import { DATA_TYPE_EVENT_CATEGORIES // DATA_TYPE_EXHIBITORS,
, DATA_TYPE_EVENTS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS, DATA_TYPE_SERVICES } from 'data/config/dataConfig';
import { LIST_PAGE_KEY // LIST_GROUPS_PAGE_KEY,
// LOGIN_PAGE_KEY,
, MAP_PAGE_KEY, MEDIAS_PAGE_KEY // SEARCH_TAIGA_PAGE_KEY,
} from 'src/pages/pagesKeys';
import AdSwap from 'src/components/ad-swap/AdSwap';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import { AD_KEYS } from 'src/core/config-json/ConfigJsonManager';
var LOG_PREF = '[homeConfig] '; // const { DEFAULT_PROFILE } = require('./profiles');
// NB: used for ChooseProfile page too !

export var getStyle = function getStyle() {
  return {
    background: 'url(' + getUrl('files/project/home/home_background.jpg') + ') no-repeat',
    backgroundSize: 'contain'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 2
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 220
};
export var GENERAL_TILE_OPTIONS = {
  height: 0.65,
  // rowspan
  width: 1 // colspan

}; // const tileBgGradient = 'linear-gradient(rgba(17, 17, 17, 0.65), rgba(85, 85, 85, 0.65))'

var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem( // Style
  {
    container: {
      style: {},
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")")
      } //className: 'home-icon-plan',

    }
  }, // Label (i18n)
  homebutton['label_' + lang], // Action to perform on click
  function (actions) {
    var url = homebutton['link_' + lang],
        target = homebutton['target'];

    if (openUrl(url, null, target, true)) {
      actions.linkClicked(url);
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getParticipantsSearchTile = function getParticipantsSearchTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Contacts.svg') + ')'
      }
    }
  }, // Label (i18n)
  'home.miseEnRelation', // Page & props
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getDefault = function getDefault(lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adKey: AD_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ['inbox', 'klipsoleads', 'contactScan']
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [// Programme
      createItemRoute( // Style
      {
        container: {
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Programme.svg') + ')'
          } // className: 'home-icon-programme',

        }
      }, // Label (i18n)
      'home.agenda', // Page & props
      LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
      {
        inputs: [{
          dataType: DATA_TYPE_EVENTS
        }]
      }, // Tile options
      GENERAL_TILE_OPTIONS), // Speakers
      createItemRoute( // Style
      {
        container: {
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Speakers.svg') + ')'
          } // className: 'home-icon-intervenants',

        }
      }, // Label (i18n)
      'home.speakers', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: "",
        inputs: [{
          dataType: DATA_TYPE_SPEAKERS
        }]
      }, // Tile options
      GENERAL_TILE_OPTIONS), // MISE EN RELATION
      getParticipantsSearchTile(), // Social Medias
      createItemRoute( // Style
      {
        container: {
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/News.svg') + ')'
          }
        }
      }, // Label (i18n)
      'home.medias', // Page & props
      MEDIAS_PAGE_KEY, null, // Tile options
      GENERAL_TILE_OPTIONS), // PLAN
      createItemRoute( // Style
      {
        container: {
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Map.svg') + ')'
          } //className: 'home-icon-plan',

        }
      }, // Label (i18n)
      'home.map', // Page & props
      MAP_PAGE_KEY, null, // Tile options
      GENERAL_TILE_OPTIONS), // INFOS PRATIQUES
      hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null // createItemRoute(
      //     // Style
      //     {   container: {
      //           className: 'home-btn-text',
      //         },
      //         icon: {
      //             style: {
      //                 backgroundImage: 'url('+getUrl('files/project/home/Services.svg')+')',
      //             },
      //         },
      //     },
      //     // Label (i18n)
      //     'home.practicalInfo',
      //     // Page & props
      //     LIST_PAGE_KEY,
      //     { contextualTitle: "",
      //       inputs: [{ dataType: DATA_TYPE_SERVICES }]},
      //     // Tile options
      //     GENERAL_TILE_OPTIONS,
      // ),
      // INTERACTIVITE ATELIERS
      //                    hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
      ]
    }
  }, {
    component: AdSwap,
    props: {
      adKey: AD_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @param  {boolean} isLoggedIn
 * @return {object}
 */


export function get(profile, lang, adConfig, orientation, isLoggedIn) {
  return getDefault(lang, adConfig, orientation, isLoggedIn);
}