
import config from 'data/config/config';

import { SET_LOGIN_STATUS } from 'src/store/actionTypes';

import { getParticipants, clearData } from './ParticipantsService';


let isLoggedIn = false;

export default ({ dispatch, getState }) => next => action => {
    const result = next(action);

    switch (action.type) {

        case SET_LOGIN_STATUS:
            if (isLoggedIn !== action.loggedIn // log status changed
                    && config.NETWORKING
                    && config.NETWORKING.FEATURE_ENABLED) {

                if (action.loggedIn) {
                    // The user is now logged in
                    if (config.NETWORKING.FETCH_FULL_PARTICIPANTS_DATA_ON_LOGIN) {
                        // Init participants data on log in
                        getParticipants();
                    }
                } else {
                    // User logged off
                    // Clear participants data
                    clearData();
                }
            }
            isLoggedIn = action.loggedIn;
            break;

        default: // for linter
    }

    return result;
}