
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import UserDataContent, { PROFILE_TAB_KEY } from './UserDataContent';
import Loader from 'src/components/loader/Loader';

import * as actions from 'src/store/actions';

import './UserDataPage.scss';

const LOG_PREF = '[UserDataPage]';


class UserDataPage extends Component {

    pageKey = USER_DATA_PAGE_KEY

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.props.labels.userData.title);
        }
    }

    getCurrentTabIndex() {
        if (this.props.userDataProps && this.props.userDataProps.input && this.props.userDataProps.input.tabIndex) {
            return this.props.userDataProps.input.tabIndex;
        }
        else if (this.props.tabIndex) {
            return this.props.tabIndex;
        }
        else {
            return PROFILE_TAB_KEY;
        }
    }

    render() {
        console.log(LOG_PREF+'render');
        // if (!this.props.isVisible) return null;

        return (
            <>
                <Menu
                    options={this.props.menuOptions}
                    actions={this.props.actions}
                    labels={this.props.labels}
                    profile={this.props.profile}
                    associatedPageKey={this.pageKey}
                    adConfig={this.props.adConfig}
                    twoColumns={this.props.twocolumns}
                    isLocationEnabled={this.props.isLocationEnabled}
                />

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={this.props.labels.userData.title}
                    pageKey={this.pageKey}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}
                />

                { !this.props.userData
                    ? <Loader labels={this.props.labels} />
                    : <UserDataContent
                        userData={this.props.userData}
                        contacts={this.props.contacts}
                        contactedParticipants={this.props.contactedParticipants}
                        searchResults={this.props.searchResults}
                        profile={this.props.profile}
                        tabIndex={this.getCurrentTabIndex()}
                        status={this.props.status}
                        sections={this.props.sections}
                        formStatus={this.props.formStatus}
                        formSections={this.props.formSections}
                        tosLink={this.props.tosLink}
                        tosLinkTarget={this.props.tosLinkTarget}
                        externalResourceLinkTarget={this.props.externalResourceLinkTarget}
                        logout={this.props.logout}
                        keyboardHeight={this.props.keyboardHeight}
                        labels={this.props.labels}
                        actions={this.props.actions}
                    />
                }
            </>
        );
    }
};

UserDataPage.propTypes = {
    userData: PropTypes.object,
    tabIndex: PropTypes.string,

    contacts: PropTypes.array,
    contactedParticipants: PropTypes.array,
    searchResults: PropTypes.object,

    formSections: PropTypes.array.isRequired,
    formStatus  : PropTypes.object,
    tosLink     : PropTypes.string,
    logout      : PropTypes.func.isRequired,

    // Common
    keyboardHeight: PropTypes.number,
    adConfig      : PropTypes.object,
    twocolumns    : PropTypes.bool,
    menuOptions   : PropTypes.object.isRequired,
    profile       : PropTypes.string,
    isActive      : PropTypes.func.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLocationEnabled : PropTypes.bool,
    isVisible     : PropTypes.bool, // set by togglePageAfterNavigation common reducer function
    labels        : PropTypes.object.isRequired,
    actions       : PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[USER_DATA_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserDataPage);
