function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
export const saveAndOpenbase64AsFile = (folderpath, filename, content, contentType) => {
  // Convert the base64 string in a Blob
  var DataBlob = b64toBlob(content, contentType);

  window.resolveLocalFileSystemURL(folderpath, function(dir) {
    dir.getFile(filename, { create: true }, function(file) {
      file.createWriter(
        function(fileWriter) {
          fileWriter.write(DataBlob);
          var finalPath = folderpath + filename;
          cordova.plugins.fileOpener2.open(
            finalPath, // You can also use a Cordova-style file uri: cdvfile://localhost/persistent/Downloads/starwars.pdf
            contentType,
            {
              error: function(e) {
                console.log("Error status: " + e.status + " - Error message: " + e.message);
              },
              success: function() {}
            }
          );
        },
        function() {
          alert("Unable to save file in path " + folderpath);
        }
      );
    });
  });
};

export const saveBase64AsFile = (folderpath, filename, content, contentType, cb) => {
  // Convert the base64 string in a Blob
  var DataBlob = b64toBlob(content, contentType);

  window.resolveLocalFileSystemURL(folderpath, function(dir) {
    dir.getFile(filename, { create: true }, function(file) {
      file.createWriter(
        function(fileWriter) {
          fileWriter.onwrite = function(evt) {
            var finalPath = folderpath + filename;
            cb(finalPath);
          };
          fileWriter.write(DataBlob);
        },
        function() {
          alert("Unable to save file in path " + folderpath);
        }
      );
    });
  });
};
