// NB: to build a cordova app, relative path is needed here
var _require = require('../../src/pages/pagesKeys'),
    HOME_PAGE_KEY = _require.HOME_PAGE_KEY;

var dataConfig = require('./dataConfig');

var _require2 = require('../../src/core/webservices/WsProviders'),
    TAIGA = _require2.TAIGA;

var projectConfig = require('../../../data/config.js');

var _require3 = require('./profiles'),
    DEFAULT_PROFILE = _require3.DEFAULT_PROFILE;

var _require4 = require('./dataConfig'),
    DATA_TYPE_EVENT_CATEGORIES = _require4.DATA_TYPE_EVENT_CATEGORIES,
    DATA_TYPE_EVENTS = _require4.DATA_TYPE_EVENTS;

var codificationsConfig = require('./codificationsConfig'); // Only export a default object, to be able to
// override properties depending on target environment


module.exports = {
  EVENT_NAME: 'AEC 2020',
  // Misc UI
  DELAY_BEFORE_CLICK_ACTION: 50,
  //ms
  SHOW_TERMS_OF_USE_ON_HOME: true,
  // Terms of use URL
  TOU_LINK: {
    en: 'http://kim1.mobile-spot.com/ademe/aec/cgu/cgu_fr.html',
    fr: 'http://kim1.mobile-spot.com/ademe/aec/cgu/cgu_fr.html'
  },
  TOU_LINK_TARGET: '_blank',
  // App profiles
  SUPPORTED_PROFILES: [DEFAULT_PROFILE],
  DEFAULT_PROFILE: DEFAULT_PROFILE,
  // Routing (in two columns mode, = default page on left column)
  getHomePage: function getHomePage(profile) {
    switch (profile) {
      case DEFAULT_PROFILE:
        return {
          pageKey: HOME_PAGE_KEY,
          props: null
        };

      /*
      case WEBAPP_EXH_PROFILE: return {
          pageKey: LIST_PAGE_KEY,
          props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EXHIBITOR_CATEGORIES }] },
      }
       case WEBAPP_EVT_PROFILE: return {
          pageKey: LIST_PAGE_KEY,
          props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EVENT_CATEGORIES }] },
      }
       case WEBAPP_SPK_PROFILE: return {
          pageKey: LIST_PAGE_KEY,
          props: { inputs: [{ dataType: dataConfig.DATA_TYPE_SPEAKERS }]},
      }
      */

      default:
        console.error('Bad configuration, unexpected profile: ' + profile);
    }
  },
  APP_TITLE_SEPARATOR: ' - ',
  ROUTE_SEPARATOR: '?',
  // Lang
  SUPPORTED_LANGS: ['fr'],
  DEFAULT_LANG: 'fr',
  // Backend / Updates
  UPDATE_ENABLED: true,
  BO_SSH_USER: 'www',
  // Map
  MOBIGEO_API_KEY: 'Xieloo3a',
  // Mobile-Spot link
  MOBILESPOT_WWW: 'https://www.ademe.fr/',
  // hack for url ademe
  // SHARE:
  DEFAULT_SHARE_IMAGE: '',
  //  on LinkedIn
  LINKEDIN_API_KEY: '',
  //  on Facebook
  FACEBOOK_APP_ID: '',
  WEB_URL: projectConfig.web.url,
  // In web mode, this fallback url can be used if the user's
  // browser does not meet some requirements (eg IE<11)
  WEB_FALLBACK: '',
  // e.g com.printemps.haussmann.dev
  NATIVE_ID: projectConfig.native.id,
  // Note: handling urls depending on environment is welcomed.
  // in that case, declare this variable in config-[env].js
  ADDITIONAL_DEFAULTSRC_CSP: [],

  /**
   * FEATURES
   */
  DOWNLOAD_FAVORITES: {
    FEATURE_ENABLED: false
  },
  APPOINTMENT_REQUEST: {
    FEATURE_ENABLED: false // webservice url is defined per environment

  },
  CONTRIBUTIONS_FEED: {
    FEATURE_ENABLED: false
  },
  FLIGHTS_SCHEDULE: {
    FEATURE_ENABLED: false
  },
  LOGIN: {
    FEATURE_ENABLED: true,
    displayLabel: true,
    PROVIDER: TAIGA,
    // Note: webservice url is defined per environment
    forgottenPasswordURL: null,
    forgottenPasswordURLTarget: '_blank',
    createAccountURL: null,
    createAccountURLTarget: '_blank',
    tosURL: null,
    tosURLTarget: '_blank'
  },
  POLL: {
    FEATURE_ENABLED: false
  },
  USER_DATA: {
    FEATURE_ENABLED: true,
    PROVIDER: TAIGA,
    SECTIONS: [{
      className: '',
      label: '',
      displayLabel: true,
      fields: {
        profilePicture: {
          type: 'image',
          editable: true
        },
        firstName: {
          type: 'text',
          required: true,
          editable: false
        },
        lastName: {
          type: 'text',
          required: true,
          editable: false
        },
        organizationName: {
          type: 'text',
          editable: false
        },
        position: {
          type: 'text',
          required: true,
          editable: false
        },
        email: {
          type: 'email',
          required: true,
          pattern: /^[\w._+-]+@[\w.-]+\.[A-Za-z]{2,64}$/,
          editable: false
        }
        /*phone: {
            type: 'tel',
            required: true,
            pattern: /^[ \.\+0-9]*$/,
            editable: true,
        },*/

      }
    }, {
      className: 'ud-section-switches',
      label: 'userData.notice',
      // i18n path
      fields: {
        visible: {
          type: 'bool',
          required: true,
          editable: true
        },
        contactable: {
          type: 'bool',
          required: true,
          editable: true,
          condition: 'visible'
        }
      }
    }],
    externalResourceLinkTarget: '_blank'
  },
  IMAGE_UPLOAD: {
    // see https://github.com/blueimp/JavaScript-Load-Image#options
    LOAD_IMAGE_OPTIONS: {
      maxWidth: 900,
      maxHeight: 900,
      contain: false,
      orientation: true,
      canvas: true
    },
    INPUT_ACCEPTED_MIME: 'image/*',
    OUTPUT_MIME: 'image/jpeg',
    OUTPUT_COMPRESSION: 0.92,
    DISPLAY_IMAGE_OPTIONS: {
      maxWidth: 300,
      maxHeight: 300,
      cropMinWidth: 100,
      cropMinHeight: 100,
      cropInitialWidth: 300,
      cropRatio: 0.85
    }
  },
  KLIPSO_LEADS: {
    HAS_HEADER_CLOSE_BUTTON: false,
    LICENCE_SCAN_ACCEPTED_FORMATS: 'QR_CODE',
    LICENCE_REGEXP: /^[A-Za-z0-9]{10}$/,
    TOS_LINK: 'https://www.leni.fr/mentions/klipsoleads-protection-des-donnees.htm' // the rest is by env

  },
  NETWORKING: {
    FEATURE_ENABLED: true,
    PROVIDER: TAIGA,
    FETCH_FULL_PARTICIPANTS_DATA_ON_LOGIN: true,
    FULL_PARTICIPANTS_DATA_EXPIRE_DELAY: 1 * 60 * 1000,
    FETCH_EXHIBITOR_PARTICIPANTS: true,
    FETCH_SPEAKER_PARTICIPANT: true
  },
  SEARCH_TAIGA: {
    WS_PARAMS: {
      start: 0,
      take: 100
    },
    SIMPLE_SECTIONS: [{
      className: '',
      label: '',
      buttons: [{
        key: 'simple-search-toggle-btn',
        // mandatory
        type: 'TOGGLE',
        defaultVisibleButtonIndex: 0,
        // optional (default: 0)
        content: [{
          label: 'searchTaiga.fieldToggle.byCompany',
          field: {
            organizationName: {
              type: 'text',
              label: 'searchTaiga.fields.company',
              hasSearchButton: true
            }
          }
        }, {
          label: 'searchTaiga.fieldToggle.byName',
          field: {
            lastName: {
              type: 'text',
              label: 'searchTaiga.fields.name',
              hasSearchButton: true
            }
          }
        }]
      }]
    }],
    ADVANCED_SECTIONS: [{
      className: '',
      label: '',
      fields: {
        organizationName: {
          type: 'text',
          label: 'searchTaiga.fields.company'
        },
        lastName: {
          type: 'text',
          label: 'searchTaiga.fields.name'
        },
        roles: {
          type: 'checkbox',
          multiple: true,
          label: 'searchTaiga.fields.role',
          values: {
            codif: codificationsConfig.CODIFICATION_FONCTION
          }
        }
      }
    }]
  },
  SHARE: {
    FEATURE_ENABLED: true,
    POSITION_SHARE_IMAGE: '',
    // Declare restrictions
    // If current page + current profile are found below,
    // then share button is hidden.
    RESTRICTIONS: [// e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  NOTES: {
    FEATURE_ENABLED: true,
    RESTRICTIONS: [// e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  CONTACTS: {
    FEATURE_ENABLED: false,

    /*RESTRICTIONS: [
        {
            pageKey: CONTACT_PAGE_KEY,
            for :[DEFAULT_PROFILE]
        },
        {
            pageKey: USER_DATA_PAGE_KEY,
            for :[DEFAULT_PROFILE]
        },
        // e.g
        // { pageKey: EXHIBITOR_PAGE_KEY,
        //   for : [ VISITOR_GP_PROFILE ]
        // }
    ],*/
    SAVE_CONTACT_TO_DEVICE: {
      FEATURE_ENABLED: true
      /*RESTRICTIONS: [
            {
                pageKey: CONTACT_PAGE_KEY,
                for :[DEFAULT_PROFILE]
            },
        ]*/

    }
  },
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    PUBLIC_KEY: 'eN/sCwOHNN+42fjwDoLj35xaVN0OgnXsxzV8+yvu0oy0Ea3Klk19j3EEN3uPZkw3HR8HlEpOSg999rsBwwklDv/BoRNt8RhYZoCyDfmcfhusV/w8go7jtw6JI4q4P8nfB6QgotAfpAYG/ijCOzbbXI/WG1uppA2DP8R6vb7d7WM=',
    UNAUTHORIZED_MODE_ENABLED: true,
    // webservice url is defined per environment
    // Debouncing value represents the delay between
    // last favorite toggle (add or remove) and server call
    DEBOUNCING: 10 * 1000 // ms

  },
  SOCIAL: {
    FEATURE_ENABLED: true
  },
  SYNOPTIC_AGENDA: {
    DEFAULT_INPUT: {
      agendaProps: {
        input: {
          parentId: 1,
          parentType: DATA_TYPE_EVENT_CATEGORIES,
          dataType: DATA_TYPE_EVENTS
        }
      }
    }
  }
};