
import { UPDATER_INITIALIZED, DATA_ASSETS_UPDATED } from 'src/store/actionTypes';

import { CONFIG_JSON_PATH, init } from 'src/core/config-json/ConfigJsonManager';

import { pollConfigUpdated } from 'src/store/actions';

const LOG_PREF = '[configJsonMiddleware] ';


export default ({ dispatch, getState }) => next => action => {
    const result = next(action);

    switch (action.type) {

        case UPDATER_INITIALIZED:
            init();
            break;

        case DATA_ASSETS_UPDATED:
            if (Array.isArray(action.assets) && action.assets.indexOf(CONFIG_JSON_PATH) !== -1) {
                console.info(LOG_PREF + 'Ad config update detected');
                init();
                dispatch(pollConfigUpdated());
            } else {
                console.log(LOG_PREF + 'No ad config update detected');
            }
            break;

        default:
    }
    return result;
};
