module.exports = {
  name: 'aec2020',
  client: 'AEC 2020',
  description: "Mobile app for AEC 2020",
  pluginId: 'mobigeo-plugin-aec',
  version: '1.0.0',
  validLangs: ['fr'],
  native: {
    version: '1',
    author: 'Mobile-Spot',
    backgroundColor: '0xffffff',
    // iOS
    // appleTeamId: '2BAP3P29V2',
    // iosAppStoreUrl: 'https://itunes.apple.com/app/paris-air-show/id1484463502',
    iosBuildTarget: '11.0',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    // playStoreUrl: 'https://play.google.com/store/apps/details?id=com.mobilespot.sofcot',
    androidFadeSplashScreenDuration: 750,
    androidSigning: {
      keyAlias: 'mobilespot',
      keyStorePassword: 'jee1Uu0Hieloh7bi'
    },
    // Web app to wrap in the cordova app ('ionic/www', 'simple', 'test', 'demo', 'app-react' - see cordova/testapps/)
    app: 'app-react'
  },
  // List the folders of the data bundles to include for this project
  data: {
    default: 'Xieloo3a'
  },
  // MobiGeo features
  // 'location', 'location.geogroup', 'location.geopush', 'map.route', 'assets.update', 'poi.aroundme', 'poi.states'
  features: [],
  location: {
    mapping: {},
    registering: {
      location: false,
      geofencing: false
    }
  },
  undeliveredFolders: ['source', 'exports'],
  // old way to tell which additional repos need to be cloned (left for backward compatibility, when mobigeo-cli has not been updated yet)
  cordova: true,
  // new way to tell which additional repos need to be cloned
  relatedRepos: [{
    src: 'git@bitbucket.org:mobilespotdev/cordova.git',
    postCommands: ['cd cordova && npm install']
  }, {
    src: 'git@bitbucket.org:mobilespotdev/mobigeo.git',
    postCommands: ['cd mobigeo && npm install']
  }, {
    src: 'git@bitbucket.org:mobilespotdev/app-react.git'
  }, {
    src: 'git@bitbucket.org:mobilespotdev/data-aec.git',
    dest: 'app-react/data',
    postCommands: ['cd app-react && yarn', 'cd app-react && npm run set-env dev', 'cd app-react && npm run download-assets', 'cd app-react && npm run download-data-files', 'cd app-react && npm run update-mobigeo']
  }],
  crypto: false,
  cordova_plugins: {
    mobigeo_plugin_dependencies: [],
    app_dependencies: [{
      id: '../src-app-plugins/cordova-plugin-mobilespot-app',
      variable: 'HOST',
      varValue: 'web.url'
    }, {
      id: '../src-app-plugins/cordova-plugin-mobilespot-badge-scan'
    }, // for warning message: 'config file undefined requested for changes not found ...'
    // see: https://github.com/Pushwoosh/pushwoosh-phonegap-plugin/issues/297#issuecomment-418101837
    // { id: 'pushwoosh-cordova-plugin' },
    {
      id: 'https://github.com/Mobile-Spot/pushwoosh-phonegap-plugin.git'
    }, {
      id: 'cordova-plugin-file'
    }, {
      id: 'cordova-plugin-file-opener2',
      variable: 'ANDROID_SUPPORT_V4_VERSION',
      varValue: '27.+'
    }]
  },
  web: {}
};