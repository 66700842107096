
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import config from '/data/config/config'

import Url from 'src/components/url/Url';
import DummyKeyboardPlaceholder from 'src/components/dummy-keyboard-placeholder/DummyKeyboardPlaceholder';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import { iosHackScrollTo0 } from 'src/core/util/browser';


class LoginContent extends Component {

    state = {
        login: null,
        password: null,
    }

    handleLoginChange = (event) => {
        this.setState({ login: event.target.value });
    }

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }

    handleLoginClicked = () => {
        if (this.props.isLogging) {
            return;
        }
        if (!this.loginField.value || !this.passwordField.value) {
            this.props.actions.showNotification({ message: this.props.labels.login.fillTheFields });
            return;
        }
        this.props.actions.startLogin(this.loginField.value, this.passwordField.value);
    }

    handleLogoutClicked = () => {
        this.props.logout();
    }

    handleProfileClicked = () => {
        this.props.actions.navigate(USER_DATA_PAGE_KEY);
    }

    setLoginField = el => {
        this.loginField = el;
    }

    setPasswordField = el => {
        this.passwordField = el;
    }

    getNotLoggedUI = () => (
        <div className="login-content">
            <form>
                <div className="login-form-row">
                    <i className="fal fa-user-circle fa-2x text-color1"></i>
                    <div className='login-input-group'>
                        {config.LOGIN.displayLabel && <label htmlFor="login" className='text-color1'>{this.props.labels.login.loginLabel}</label>}
                        <input
                            className="generic-input"
                            ref={this.setLoginField}
                            value={this.state.login || ''}
                            placeholder={this.props.labels.login.loginPlaceholder}
                            onChange={this.handleLoginChange}
                            onBlur={iosHackScrollTo0}
                            name="login"
                            type="email"
                            autoComplete="username" />
                    </div>
                </div>

                <div className="login-form-row">
                    <i className="fal fa-lock-alt fa-2x text-color1"></i>
                    <div className='login-input-group'>
                        {config.LOGIN.displayLabel && <label htmlFor="password" className='text-color1'>{this.props.labels.login.passwordLabel}</label>}
                        <input
                            className="generic-input"
                            ref={this.setPasswordField}
                            value={this.state.password || ''}
                            placeholder={this.props.labels.login.passwordPlaceholder}
                            onChange={this.handlePasswordChange}
                            onBlur={iosHackScrollTo0}
                            name="password"
                            type="password"
                            autoComplete="current-password" />
                    </div>
                </div>
            </form>
            {this.props.urls &&
                <div className="links">
                    {this.props.urls.forgottenPassword &&
                        <Url href={this.props.urls.forgottenPassword}
                            label={this.props.labels.login.forgottenPassword}
                            target={this.props.urlsTarget.forgottenPassword}
                        />
                    }
                    {this.props.urls.createAccount &&
                        <Url href={this.props.urls.createAccount}
                            label={this.props.labels.login.createAccount}
                            target={this.props.urlsTarget.createAccount}
                        />
                    }
                </div>
            }

            <div className="generic-btn-container">
                <div
                    className={'generic-btn cta-modal-btn -round' + (this.props.isLogging ? ' modal-btn-disabled' : '')}
                    onClick={this.handleLoginClicked}>{this.props.isLogging ? this.props.labels.login.loading : this.props.labels.login.loginButton}</div>
            </div>
            <div className="login-text">{this.props.labels.login.loginDescription}</div>

            {this.props.urls.tos &&
                <div className="login-tou-message">{this.props.labels.login.touMessage}
                    <Url href={this.props.urls.tos}
                        label={this.props.labels.login.tos}
                        target={this.props.urlsTarget.tos}
                    />
                </div>
            }

        </div>
    )

    getLoggedUI = () => (
        <div>
            <p>{this.props.labels.login.loginSuccess}</p>
            {this.props.userData && this.props.userData.firstName && this.props.userData.lastName &&
                <p>{this.props.userData.firstName} {this.props.userData.lastName}</p>
            }

            <p>{this.props.labels.login.introductoryText}</p>

            <div className="generic-btn-container">
                {this.props.userData &&
                    <div className="generic-btn" onClick={this.handleProfileClicked}>{this.props.labels.login.profile}</div>
                }
                <div className="generic-btn" onClick={this.handleLogoutClicked}>{this.props.labels.login.logout}</div>
            </div>
        </div>
    )

    getErrorMessage() {
        switch (this.props.error) {

            case WS_ERRORS.AUTH:
                return <div className="error-message">{this.props.labels.login.badLogin}</div>;

            case WS_ERRORS.NETWORK:
                return <div className="error-message">{this.props.labels.login.noNetwork}</div>;

            case null:
            case undefined:
            case '':
                return null;

            default: console.error('Unmanaged login error: ' + this.props.error);
        }
    }

    render() {
        return (
            <div id="login-content" className="content-below-apptoolbar">
                {/* <div className="title-font">{this.props.labels.login.title}</div> */}
                <div className="login-main content-font scrollbars-hidden">
                    {this.props.isLogged ? this.getLoggedUI() : this.getNotLoggedUI()}

                    {this.getErrorMessage()}
                </div>

                <DummyKeyboardPlaceholder keyboardHeight={this.props.keyboardHeight} />
            </div>
        );
    }
};

LoginContent.propTypes = {
    userData: PropTypes.object,
    isLogging: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    urls: PropTypes.object,
    error: PropTypes.string,
    keyboardHeight: PropTypes.number,
    labels: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

export default LoginContent;