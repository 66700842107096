
// import queryString from 'query-string';

import config from 'data/config/config';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { WS_ERRORS, getErrorFromHttpStatus } from '../WS_ERRORS';
import STATUSES from '../statuses';

const LOG_PREF = '[CodificationsWS] ';


export function getCodifications(codifications, cb) {
    if (Array.isArray(codifications) !== true || codifications.length === 0) {
        console.error(LOG_PREF+'Skipping getCodifications web service call '
            + 'because no codifications has been passed as argument');
        return;
    }

    // sample: https://www.assises-economie-circulaire.ademe.fr/tgServices/AdemeAppWs/getCodifs.htm?fields[0][sourceCode]=EXHIBIS&fields[0][entityCode]=IndividuEvt&fields[0][nameField]=EvenementFonction
    const url = config.BO_TAIGA_WS_URL+'/getCodifs.htm?';

    // console.log(queryString.stringify({ fields: codifications }, { arrayFormat: 'index' }));

    let queryStringEncoded = (
        // Yup, that's some freaking format expected by the backend
        codifications.map(function(codif, index) {
            return Object.keys(codif)
                         .map(codifKey => `fields[${index}][${codifKey}]=${codif[codifKey]}`)
                         .join('&')
        }).join('&')
    );

    let opts = {
        method: 'GET',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        withCredentials: true, // use cookie (the browser takes care of it)
    };

    fetchHelper(
        url+queryStringEncoded,
        opts,
        true, // parse response as json
        function onSuccess(response) {
            switch(response.status) {
                case STATUSES.SUCCESS:
                    cb(
                        null, // error code
                        response.datas.fieldsInfo,
                    );
                    break;

                case STATUSES.ERROR:
                    cb(getErrorFromHttpStatus(response.http_code));
                    break;

                default: console.error(LOG_PREF+'Unexpected response status: '+response.status);
            }
        },
        function onFailure(error, message) {
            cb(WS_ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}
if (config.ENV === 'dev') {
    global.getCodifications = getCodifications;
}
