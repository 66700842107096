
import React from 'react';
import PropTypes from 'prop-types';

import { SEARCH_MIN_CHARS } from 'data/config/searchConfig';
import { DATA_TYPE_CONTACTED_PARTICIPANTS } from 'data/config/dataConfig';
import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';
// import STATUS from 'src/store/fetchStatuses';

import DetailDataList from 'src/components/detail-data-list/DetailDataList';
import SearchField from 'src/components/search/SearchField';
import DummyKeyboardPlaceholder from 'src/components/dummy-keyboard-placeholder/DummyKeyboardPlaceholder';

import './ContactedParticipants.scss';


class ContactedParticipants extends React.Component {

    constructor(props) {
        super(props);

        this.performSearch = this.performSearch.bind(this);
        this.clearSearchResults = this.clearSearchResults.bind(this);
    }

    performSearch(value) {
        this.props.actions.searchOnASingleDataType(
            value,
            USER_DATA_PAGE_KEY,
            DATA_TYPE_CONTACTED_PARTICIPANTS,
            this.props.participants
        );
    }

    clearSearchResults() {
        this.props.actions.clearSearchResults(USER_DATA_PAGE_KEY);
    }

    render() {
        let participants;
        if (this.props.searchResults) {
            participants = this.props.searchResults[DATA_TYPE_CONTACTED_PARTICIPANTS];
        } else {
            participants = this.props.participants;
        }

        return (
            <div className="ud-contacted-participants">
                <SearchField
                    labels={this.props.labels}
                    placeholder={this.props.labels.userData.contactedParticipantsSearchPlaceholder}
                    clearResults={this.clearSearchResults}
                    perform={this.performSearch}
                    minChars={SEARCH_MIN_CHARS}
                    hasClearButton={true}
                    hasCancelButton={false}
                />

                <DetailDataList
                    dataType={DATA_TYPE_CONTACTED_PARTICIPANTS}
                    items={Array.isArray(participants) ? participants : []}
                    //isPending={Array.isArray(participants) ? false : participants.status === STATUS.PENDING}
                    actions={this.props.actions}
                    labels={this.props.labels}
                    pageKey={USER_DATA_PAGE_KEY}
                />

                <DummyKeyboardPlaceholder
                    keyboardHeight={this.props.keyboardHeight}
                />
            </div>
        );
    }

}

ContactedParticipants.propTypes = {
    participants: PropTypes.array.isRequired,
    searchResults: PropTypes.object,
    searchedString: PropTypes.string,
    keyboardHeight: PropTypes.number,
    labels: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

export default ContactedParticipants;