
import config from 'data/config/config';
import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';

import Pages from 'src/pages/Pages';

import {
    CLEAR_SEARCH_RESULTS,
    DATA_ASSETS_UPDATED,
    NAVIGATE,
} from 'src/store/actionTypes';

import { performSearch } from 'src/store/actions';


let shouldRefreshResults = false;


function refreshResults(dispatch, string) {
    if (string) { // safety net, not supposed to happen
        dispatch(performSearch(string, SEARCH_PAGE_KEY));
    }
    shouldRefreshResults = false;
}


export default ({ dispatch, getState }) => next => action => {
    const result = next(action);

    if (Pages[SEARCH_PAGE_KEY]) {
        let pageState = getState()[SEARCH_PAGE_KEY];

        switch (action.type) {

            // AD-9 : Automatically refresh search results when data is updated
            case DATA_ASSETS_UPDATED:
                if (!!pageState.searched) {
                    if (pageState.isVisible) {
                        // Refresh results now
                        refreshResults(dispatch, pageState.searched);
                    } else {
                        // Results should be refreshed on next pas display
                        shouldRefreshResults = true;
                    }
                }
                break;

            case CLEAR_SEARCH_RESULTS:
                if (action.pageKey === SEARCH_PAGE_KEY) {
                    shouldRefreshResults = false;
                }
                break;

            case NAVIGATE:
                if (action.pageKey === SEARCH_PAGE_KEY && shouldRefreshResults) {
                    refreshResults(dispatch, pageState.searched);
                }
                break;

            default: // for linter
        }
    }

    return result;
}