
// Lib
import moment from 'moment';

// conf
import config from 'data/config/config';

import { getParameterByName } from 'src/core/util/JsTools';


const LOG_PREF = '[Lang] ';

const QUERY_PARAMETER_KEY = 'lang';
const LANG_LOCALSTORAGE_KEY = 'lang';


let lang, // current lang
    labels; // Labels for current lang


/**
 * Sub-mobule responsible for persistence
 * @type {Object}
 */
const persistence = {
    get lang(){
        return localStorage.getItem(LANG_LOCALSTORAGE_KEY);
    },
    set lang(value){
        // We assume the value has already been checked by function `setCurrent`
        localStorage.setItem(LANG_LOCALSTORAGE_KEY, value);
    }
};


/**
 * Fetch labels for a language and set them as current set
 * @param {string} language
 */
const fetchLabels = language => {
    labels = require('../../data/i18n/'+language+'.js').default;
};


/**
 * Get current langage
 * @return {string}
 */
export const getCurrent = () => lang;


/**
 * Set current language
 * @param  {string} value
 * @return {boolean} language successfully set
 */
export const setCurrent = value => {
    if (value === lang) {
        // Same value
        return;
    }
    else if (config.SUPPORTED_LANGS.indexOf(value) === -1) {
        console.warn(LOG_PREF+'Unsupported language '+value+' default will be used: '+config.DEFAULT_LANG);
        lang = config.DEFAULT_LANG;
    }
    else {
        lang = value;
        console.log(LOG_PREF+'Setting language: '+lang);

        // Set for date/time formats too
        moment.locale(lang);
    }

    // Store current language in a persistent location
    persistence.lang = lang;

    // Fetch labels for the new language
    fetchLabels(lang);

    return labels;
};


/**
 * Return all labels
 * @return {object}
 */
export const get = () => labels;


/**
 * Language is determined as follow :
 *      - TODO: overriding query param ?
 *   - look if a value has previously been stored
 *   - else read browser configuration
 *   - anyway if the language is not supported, apply default setup
 */
export const init = () => {
    const queryValue = getParameterByName(QUERY_PARAMETER_KEY);

    setCurrent(queryValue || persistence.lang || (window.navigator.userLanguage || window.navigator.language).slice(0,2));
};


export const formatDate = input => input ? moment.utc(input).format('l') : '';

export const formatDayMonth = input => input ? moment.utc(input).format('D MMM') : '';

export const formatDayMonthLong = input => input ? moment.utc(input).format('ddd [<b>]D[</b>] MMM') : '';

export const formatDayMonthVeryLong = input => input ? moment.utc(input).format('dddd D MMMM') : '';

export const formatDayLong = input => input ? moment.utc(input).format('ddd DD/MM') : '';

export const formatTime = input => input ? moment.utc(input).format('LT') : '';

export const formatDateAndTime = input => input ? moment.utc(input).format('L LT') : '';

/**
 * @param  {date object} input
 * @param  {boolean} showTime
 * @param  {boolean} showSeconds (taken into account only is showTime is truthy)
 * @param  {string}  lang (optional - to override current lang)
 * @return {string}
 */
export function formatLongDateTime(input, showTime, showSeconds, lang) {
    if (!input) {
        return '';
    }
    if (lang) {
        // Change locale during function call
        moment.locale(lang);
    }

    let momentDate = moment.utc(input);

    // Parse date
    let dayOfWeek = momentDate.format('dddd');
    dayOfWeek = dayOfWeek.substring(0, 1).toUpperCase() + dayOfWeek.substring(1);

    let dayOfMonth = momentDate.format('D');
    let month = momentDate.format('MMMM');

    let formated;

    switch(lang || labels.id) {
        case 'en':
            formated = `${dayOfWeek}, ${month} ${dayOfMonth}`;
            if (showTime) {
                formated += ' '+momentDate.format('h:mm:ss a');
            }
            break;

        case 'fr':
            formated = `${dayOfWeek} ${dayOfMonth} ${month}`;
            if (showTime) {
                formated += ' '+momentDate.format('H:mm:ss');
            }
            break;

        default: // for linter
    }

    if (lang) {
        // Reset locale
        moment.locale(labels.id);
    }
    return formated;
}


// For dev/test purpose
if (config.ENV === 'dev') {
    global.moment = moment;
    global.formatDate = formatDate;
    global.formatDayMonth = formatDayMonth;
    global.formatDayMonthLong = formatDayMonthLong;
    global.formatDayMonthVeryLong = formatDayMonthVeryLong;
    global.formatDayLong = formatDayLong;
    global.formatTime = formatTime;
    global.formatDateAndTime = formatDateAndTime;
    global.formatLongDateTime = formatLongDateTime;
}
