
import React from 'react';
import PropTypes from 'prop-types';

import './FlagButton.scss';


function FlagButton({
    className,
    lang,
    labels,
    onClick,
}) {
    if (!lang) {
        return null;
    }

    let cssClasses = ['flag-select', 'flag-'+lang];

    function _onClick() {
        if (typeof onClick === 'function') {
            onClick(lang);
        }
    }

    return (
        <span
            className={`flag-select flag-${lang} ${className || ''}`}
            title={labels ? labels.lang[lang] : ''}
            onClick={_onClick}
        />
    );
}

FlagButton.propTypes = {
    className: PropTypes.string,
    lang: PropTypes.string.isRequired,
    labels: PropTypes.object,
    onClick: PropTypes.func,
};

export default FlagButton;