
// Libs
import React from 'react';
import PropTypes from 'prop-types';

// Conf
import { getSortConfig } from 'data/config/sortConfig';

// App modules
import List from 'src/components/list/List';
import Loader from 'src/components/loader/Loader';

import './DetailDataList.scss';


/**
 * Common component to display a list of items on a fiche
 *
 * @param {string} dataType
 * @param {array} items
 * @param {boolean} isPending
 * @param {object} actions
 * @param {object} labels
 * @param {string} pageKey
 */
function DetailDataList({
    dataType,
    items,
    isPending,
    actions,
    label,
    labels,
    pageKey,
}) {

    let length = Array.isArray(items) ? items.length : 0;

    // Skip component display
    if (length === 0 && isPending !== true) {
        return null;
    }

    const separatorLabel = label || labels.data[dataType][length > 1 ? 'plural' : 'singular'];

    // sort items
    const sortFunction = getSortConfig()[dataType];
    const _items = sortFunction && sortFunction.sortFunction
                        ? items.sort(sortFunction.sortFunction)
                        : items;

    return (
        <div className="detail-data-list">
            <li className="fiche-separator">{separatorLabel}</li>
            <div className="fiche-related-data">
                { isPending
                    ? <Loader labels={labels} />
                    : (
                        <List
                            isPending={false}
                            dataType={dataType}
                            actions={actions}
                            labels={labels}
                            items={_items}
                            displayFavorites={false}
                            pageKey={pageKey}
                        />
                    )
                }
            </div>
        </div>
    );
}

DetailDataList.propTypes = {
    dataType: PropTypes.string.isRequired,
    items   : PropTypes.array,
    isPending: PropTypes.bool,
    label   : PropTypes.string, // Override default label
    labels  : PropTypes.object.isRequired,
    actions : PropTypes.object.isRequired,
    pageKey : PropTypes.string.isRequired,
};

export default DetailDataList;
