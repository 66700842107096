
import React from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';

import { LIST_PAGE_KEY, FLIGHTS_SCHEDULE_PAGE_KEY } from 'src/pages/pagesKeys';

import {
    DATA_TYPE_FLIGHTS_SCHEDULE,
} from 'data/config/dataConfig';

import AdSwap           from 'src/components/ad-swap/AdSwap';
import AllOnMapButton   from 'src/components/all-on-map-button/AllOnMapButton';
import CTAButton        from 'src/components/cta-button/CTAButton';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import Loader           from 'src/components/loader/Loader';
import Url              from 'src/components/url/Url';
import { stripToIds }   from 'src/pages/map/mapUtil';
import { isSafari, isIOS } from 'src/core/util/browser';
import { convertDataTypeToMobigeoType } from 'src/core/data-and-assets/Db';
import { getAdKeyForPage } from 'src/core/config-json/ConfigJsonManager';
import { renderList } from 'src/components/list/listUtil';


class ListPageContent extends React.Component {

    state = {}
    pageKey = LIST_PAGE_KEY

    getDetail = () => renderList({
        items: this.props.items,
        status: this.props.status,
        favIconDisabled: this.props.favIconDisabled,
        favorites: this.props.favorites,
        userData: this.props.userData,
        actions: this.props.actions,
        labels: this.props.labels,
        pageKey: this.pageKey,
        listHeight: this.state.listHeight,
    })

    onDimensionsUpdate = measure => {
        this.setState({ listHeight: measure.client.height });
    }

    isListEmpty = () => {
        /* Reminder about `items` prop structure (see listPageReducer function _parseItems)

         request not performed or ongoing:
           items: null

         request performed:
           items: {
             datatype1:{
               data: object (structure depends if list is alphabetical or not)
               length: number
             },
             datatype2:{
               // same as above
             }
           }
        */

        let noData,
            hasFetched,
            totalItemsCount = 0;

        if (!this.props.items) {
            hasFetched = false;
            noData = false;
        } else {
            hasFetched = true;
            Object.keys(this.props.items).forEach(dataType => {
                totalItemsCount += this.props.items[dataType].length;
            });
            noData = totalItemsCount === 0;
        }

        return {
            noData,
            hasFetched,
        };
    }

    renderDisplayPdfButton = () => {
        if (this.props.hasDisplayPdfButton) {
            return (
                 <div className="cta-btn-container">
                    <Url
                        callback={this.props.actions.linkClicked}
                        href={this.props.agendaAsPdfUrl}
                        noCache // disable cache
                        target="_blank" className="cta-btn"
                        alt={this.props.labels.common.openAgendaAsPdf}>

                            {this.props.labels.common.openAgendaAsPdf}
                    </Url>
                </div>
            )
        }

        return null
    }

    handleCustomPoiState = () => {
        var items = this.props.items,
            striped = [];

        Object.keys(items).forEach(dataType => {
            let mobigeoType = convertDataTypeToMobigeoType(dataType);

            // Indexed list (Alphabetical)
            if (items[dataType].data) {
                Object.keys(items[dataType].data).forEach(index => {
                    striped = striped.concat(this._stripItems(items[dataType].data[index], mobigeoType, this.props.customStateOnMap));
                });
            }
            // Not indexed list
            else {
                striped = striped.concat(this._stripItems(items[dataType], mobigeoType, this.props.customStateOnMap));
            }
        });

        this.props.actions.showCustomPoiStateOnMap(striped);
        // this.props.actions.showAllPoisOnMap(striped);
    }

    _stripItems = (items, mobigeoType, poiState) => {
        let _items = [];
        items.forEach(item => {
            if (item.isSeparator !== true) {
                _items.push({
                    //id: item.id,
                    id: item.original_id,
                    type: mobigeoType,
                    ...poiState
                });
            }
        });
        return _items
    }

    _renderContent = ref => {
        const { hasFetched, noData } = this.isListEmpty()

        return (
            <div ref={ref} className="list-page-content">
                { !hasFetched && <Loader labels={this.props.labels} /> }
                { noData && <div className="list-empty">{this.props.labels.common.noData}</div> }
                { !noData && hasFetched && this.getDetail() }
            </div>
        );
    }

    renderContent = () => (
        // wrap if height needs to be known
        isSafari() || isIOS()
            ? <Measure client onResize={this.onDimensionsUpdate}>
                {({ measureRef }) => this._renderContent(measureRef)}
              </Measure>
            : this._renderContent(null)
    )

    render() {

        return (
            <div className={"content-below-apptoolbar "+(this.props.className || '')}>

                { this.props.isOnGeneralAircraftList &&
                    <FlightsScheduleHeader actions={this.props.actions} labels={this.props.labels} />
                }

                { this.props.header &&
                    <DetailFixedTitle
                        title={this.props.header}
                        hideFav={true}
                        labels={this.props.labels}
                    />
                }

                { this.props.isOnProgrammeRootPage &&
                    <HeaderProgramme labels={this.props.labels} />
                }

                { this.props.hasShowOnMapButton && window.MobiGeo &&
                    <AllOnMapButton
                        labels={this.props.labels}
                        actions={this.props.actions}
                        data={stripToIds(this.props.items)}
                    />
                }

                { this.props.hasGoToSynopticButton &&
                    <CTAButton
                        action={this.props.actions.navigateToSynopticWithoutContext}
                        label={this.props.labels.common.goToSynopticAgenda}
                    />
                }

                { this.props.customStateOnMap && window.MobiGeo &&
                    <CTAButton
                        action={this.handleCustomPoiState}
                        label={this.props.labels.common.showAll}
                    />
                }

                { this.renderDisplayPdfButton() }

                { this.renderContent() }

                <AdSwap
                    adKey={getAdKeyForPage(LIST_PAGE_KEY, this.props.inputs)}
                    isPageVisible={this.props.isPageVisible}
                    isAppVisible={this.props.isAppVisible}
                />

                <IphonexFlexMargin />
            </div>
        );
    }


}


export function HeaderProgramme ({ labels }) {
    return (
        <section
            style={{
                padding: "1em 0.7em",
                fontSize: '0.7em',
                color: '#007e4f',
                borderBottom: '1px solid lightgrey'
            }}>
            {labels.contributions.programmeSlogan}
        </section>
    );
}

export function FlightsScheduleHeader({ actions, labels }) {
    return (
        <CTAButton
            action={() => actions.navigate(FLIGHTS_SCHEDULE_PAGE_KEY)}
            label={labels.data[DATA_TYPE_FLIGHTS_SCHEDULE].title} />
    );
}

ListPageContent.props = {
    className: PropTypes.string,
    isOnGeneralAircraftList: PropTypes.bool,
    isOnProgrammeRootPage: PropTypes.bool,
    hasShowOnMapButton: PropTypes.bool,
    customStateOnMap: PropTypes.object,
    hasDisplayPdfButton: PropTypes.bool,
    hasGoToSynopticButton: PropTypes.bool,
    agendaAsPdfUrl: PropTypes.string,
    inputs: PropTypes.object.isRequired,
    items: PropTypes.object,
    adConfig: PropTypes.object,
    header: PropTypes.string,
    favorites: PropTypes.object,
    status: PropTypes.object,
    userData: PropTypes.object,
    favIconDisabled: PropTypes.bool,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    isPageVisible: PropTypes.bool,
    isAppVisible: PropTypes.bool,
};

export default ListPageContent;
