
import { isNonNullObject } from 'src/store/reducers/utils';

import { 
    ADD_MESSAGE,
    ADD_DUPLICATE_MESSAGE,
    MESSAGES_TYPES,
} from 'src/pages/inbox/inboxReducer';

import { playAction } from 'src/store/reducers/utils';

import {
    CORDOVA_PAUSE,
    CORDOVA_RESUME,
    CORDOVA_READY,
    CORDOVA_ACTIVE,
    NOTIF_TRIGGERED,
    NOTIF_USER_CLEAR,
    NOTIF_USER_CLEAR_ALL,
    NOTIF_CLICK,
    PROFILE_CHANGED,
    BADGE_SCAN_WITHOUT_RESULT,
} from 'src/store/actionTypes';

import { isAndroid } from 'src/core/util/browser';
import { getPendingNotifications } from 'src/core/cordova/CordovaHelper';

import { windowResized } from 'src/store/actions';


let timeOfLastScanWithoutResult;

export const getTimeOfLastScanWithoutResult = () => timeOfLastScanWithoutResult


export function reducer(state = { device: null, status: CORDOVA_ACTIVE }, action ) {

    switch (action.type) {

        case CORDOVA_READY:
            return {
                ...state,
                device: CORDOVA_READY
            };
        case CORDOVA_RESUME:
            return {
                ...state,
                status: CORDOVA_ACTIVE
            };
        case CORDOVA_PAUSE:
            return {
                ...state,
                status: CORDOVA_PAUSE
            };
        default:
            return state;
    }
}


function noopMiddleware ( store ) {
    return next => action => next(action);
}


export function createMiddleware ( cordova ) {

    if ( !cordova ) {
        return noopMiddleware;
    }
    else {
        return function middleware ( store ) {

            // subscriptions to the notification plugin
            var notif;
            if (
                isNonNullObject(cordova) &&
                isNonNullObject(cordova.plugins) &&
                isNonNullObject(cordova.plugins.notification) &&
                isNonNullObject(cordova.plugins.notification.local)
            ) {
                notif = cordova.plugins.notification.local;
            } else {
                global.NO_NOTIF = true;
            }

            if (notif) {
                [
                    [ 'click', NOTIF_CLICK ],
                    [ 'clearall', NOTIF_USER_CLEAR_ALL ],
                    [ 'trigger', NOTIF_TRIGGERED ],
                    [ 'clear', NOTIF_USER_CLEAR ]
                ].forEach(function ( [ event, type ] ) {

                    notif.on(event, function () {

                        if (arguments.length > 0) {
                            store.dispatch({
                                type,
                                data: arguments[0],
                                // can be undefined, should not be used atm
                                meta: arguments[1]
                            });

                        } else {
                            store.dispatch({
                                type
                            });
                        }
                    })
                });
            }


            if (global.isCordovaContext && notif) {
                global.scheduleMessage = notif.schedule.bind(notif);
            }


            // global subscriptions to cordova
            [
                // commented because it triggers a redux action while store is being intialized => exception
                // [ 'deviceready', CORDOVA_READY ],

                [ 'pause', CORDOVA_PAUSE ],
                [ 'resume', CORDOVA_RESUME ],
            ].forEach(([ event, type ]) => document.addEventListener(
                event,
                () => store.dispatch({ type }))
            );

            return next => action => {
                const result = next(action);

                switch (action.type) {

                    case ADD_MESSAGE:
                    case ADD_DUPLICATE_MESSAGE:
                        const { meta = {} } = action;

                        // Notif ability tells if you can notif within the app
                        // If false then schedule a notification
                        if (
                            meta.type === MESSAGES_TYPES.notification &&
                            meta.hasNotifAbility === false &&
                            notif
                        ) {

                            const { data: message } = action;
                            notif.schedule({
                                id: message.id,
                                text: message.content,
                                title: message.title,
                                data: message.id
                            });

                        } else {
                            console.log('Was not able to display a notif');
                        }
                        break;


                    case NOTIF_CLICK:
                        const messageId = action.data.data;
                        const message = store.getState().Inbox.byDate.filter(message => message.id === messageId)[0];

                        if (message && message.action) {
                            const _action = message.action;
                            setTimeout(playAction(_action, store.dispatch), 0);
                        }
                        break;


                    case CORDOVA_RESUME:
                        if (isAndroid()) {
                            // Send a delayed 'window resized' action due to issue on android
                            // when a user has a password screen lock
                            window.setTimeout(function() {
                                store.dispatch(windowResized());
                            }, 1000);
                        }
                        getPendingNotifications();
                        break;


                    case PROFILE_CHANGED:
                        // Get pending notifications on app startup
                        // but profile needs to be set first
                        // (because the user can navigate from the notification dialog)
                        getPendingNotifications();
                        break;


                    case BADGE_SCAN_WITHOUT_RESULT:
                        timeOfLastScanWithoutResult = new Date().getTime();
                        break;


                    default: // for linter
                }
                return result;

            };

        };

    }

}
