// Conf
import config from 'data/config/config';

import {
    DATA_TYPE_PLACES,
    DATA_TYPE_FAVORITE_POSITIONS,
    DATA_TYPE_BRAND_CATEGORIES,
    DATA_TYPE_EVENT_CATEGORIES,
    DATA_TYPE_EVENTS,
    DATA_TYPE_EXHIBITORS,
    DATA_TYPE_PARTICIPANTS,
    DATA_TYPE_SPEAKERS,
    CATEGORIES_DATA_TYPE,
    NON_CATEGORIES_DATA_TYPE,
    VALID_DATA_TYPES,
    CATEGORIES_MAPPING,
} from 'data/config/dataConfig';

import { SINGLE_ITEM_DATATYPES } from 'data/config/ficheConfig';

import { elementPropsGetters, shouldRedirectToMap } from 'data/config/listConfig';
import { groupItems } from 'data/config/sortConfig';

// App modules
import { setCurrent as setCurrentLang, get as getLabels } from 'src/core/Lang';
import { get as getProfile } from 'src/core/Profile';
import * as Db from 'src/core/data-and-assets/Db';
import * as Query from 'src/core/query/Query';
import { search, searchPlaces, applySearchOnSingleDataType, SEARCH_TYPES } from 'src/core/search/Search';
import * as Favorites from 'src/core/favorites/Favorites';
import { HISTORY_ACTIONS, getCurrentState } from 'src/core/navigation/History';
import { parseSpecialParameters } from 'src/core/navigation/Router';
import * as Notes from 'src/core/notes/Notes';
import * as LoginService from 'src/core/login/LoginService';
import { isSessionValid }  from 'src/core/login/LoginService';
import * as UserDataService from 'src/core/user-data/UserDataService';
import * as ParticipantsService from 'src/core/participants/ParticipantsService';
import { getContact, setFormFields, setContext, saveContacts } from 'src/core/klipso-leads/KlipsoLeadsData';
import * as Contacts from 'src/core/contacts/Contacts';
import { scanNewContact } from 'src/core/contacts/Contacts';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import STATUS from './fetchStatuses';


import Pages from 'src/pages/Pages';
import { DATA_TYPE_TO_PAGE_KEY } from 'src/pages/dataToPageMapping';

import {
    KLIPSO_LEADS_PAGE_KEY,
    LIST_GROUPS_PAGE_KEY,
    LIST_PAGE_KEY,
    LOGIN_PAGE_KEY,
    MAP_PAGE_KEY,
    SEARCH_PAGE_KEY,
    SYNOPTIC_AGENDA_PAGE_KEY,
    USER_DATA_PAGE_KEY,
} from 'src/pages/pagesKeys';

import  {
    DATA_ASSETS_UPDATING,
    DATA_ASSETS_UPDATED,
    UPDATER_INITIALIZED,

    MAP_RELOAD,
    MAP_LOADED,
    MAP_IS_RESTARTING,
    MAP_USER_LOCATED,
    MAP_USER_UNLOCATED,
    SHOW_MAP_ITINERARY,
    DISPATCH_ITINERARY,
    MAP_ZOOM_ON_ZONE,
    ITINERARY_API_CALLED,
    MOBIGEO_ERROR_THROWN,
    MAP_FAVORITE_CREATED,
    MAP_FAVORITE_SHARED,
    GEOGROUP_PSEUDO_SET,
    GEOGROUP_GROUP_CREATED,
    GEOGROUP_GROUP_JOINED,
    GEOGROUP_GROUP_QUITTED,
    REQUEST_LOCATION_CAPABILITIES,
    STOP_LOCATION,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_LOCATION_CAPABILITY_STATUS,
    TOGGLE_PMR_STATUS,
    SHOW_SEARCH_PLACE_DIALOG,
    HIDE_SEARCH_PLACE_DIALOG,
    SET_SEARCH_PLACE_DIALOG_CANCELABLE,
    PLACE_SEARCH_PERFORMED,
    SEARCHED_PLACE_SELECTED,
    CLEAR_PLACE_RESULTS,

    NAVIGATE,
    NAVIGATE_BACK,
    NAVIGATE_TO_ITEMS,
    HAS_NAVIGATED,

    ITEM_BEING_FETCHED,
    ITEM_FETCHED,
    // ITEMS_FETCHED,
    LISTS_FETCHED,
    GROUPED_ITEMS_FETCHED,

    PROFILE_CHANGED,

    LANG_CHANGED,

    SHOW_DISCLAIMER,

    SHOW_LANG_DIALOG,
    HIDE_LANG_DIALOG,

    SHOW_SHARE_DIALOG,
    HIDE_SHARE_DIALOG,

    PERFORM_LOGIN,
    SET_LOGIN_STATUS,
    USER_DATA_UPDATED,
    UPDATE_USER_DATA_REQUEST_STATUS,
    USER_DATA_TAB_INDEX_UPDATE,

    UPDATE_PAGE_STATE,

    FETCH_NOTES,

    TOGGLE_FAVORITE,
    ALL_FAVORITES_DELETED,
    FETCH_FAVORITES,
    SET_FAVORITES_SYNCHRONIZATION_STATUS,
    SYNCHRO_FAVORITES_ICON_CLICKED,
    SET_CODE_IDENTIFICATION,
    SET_TEMPORARY_CODE_IDENTIFICATION,
    SHOW_FAVORITES_CODE_DIALOG,
    HIDE_FAVORITES_CODE_DIALOG,
    FETCH_FAVORITES_CODE,
    SYNC_WITH_FAVORITES_CODE,
    SET_FAVORITES_CODE_SYNC_STEP,

    WINDOW_RESIZED,

    SEARCH_PERFORMED,
    CLEAR_SEARCH_RESULTS,
    SET_SEARCH_FIELD_VISIBLE,

    TOGGLE_MENU,

    SHOW_DATA_LIST_DIALOG,
    HIDE_DATA_LIST_DIALOG,

    CONFIG_JSON_LOADED,

    POLL_CONFIG_UPDATED,
    POLL_CONFIG_LOADED,
    SET_POLL_ID,
    SET_POLL_CODE,
    VALIDATE_POLL,
    VALIDATE_POLL_CODE,
    SHOW_POLL_DIALOG,
    HIDE_POLL_DIALOG,
    GO_TO_NEXT_POLL_STEP,
    GO_TO_PREVIOUS_POLL_STEP,
    SUBMIT_POLL,
    SET_POLL_STEP,
    SET_POLL_ERROR,
    SET_POLL_PAGE,
    SET_POLL_MARK,
    SET_POLL_COMMENT,
    SET_POLL_CHOICE,
    SET_POLL_MULTIPLE,

    FETCH_CONTRIBUTIONS_FEED,
    CONTRIBUTIONS_FEED_LOADED,
    SET_CONTRIBUTIONS_FEED_ERROR,
    ACTIVATE_CONTRIBUTIONS_REFRESH,

    FETCH_SOCIAL_FEED,
    SOCIAL_FEED_LOADED,
    SET_SOCIAL_FEED_ERROR,

    SHOW_INTERSTICIEL,
    HIDE_INTERSTICIEL,
    SHOW_INTERSTICIEL_CLOSE_BUTTON,
    HIDE_INTERSTICIEL_CLOSE_BUTTON,

    SHOW_FULL_LOADER,
    HIDE_FULL_LOADER,

    AD_CLICKED,
    LINK_CLICKED,

    SHOW_NOTIF,
    EDIT_NOTIF,
    REMOVE_NOTIF,

    FLIGHT_CLICKED,

    SEND_APPOINTMENT_REQUEST,
    APPOINTMENT_REQUEST_SENT,
    APPOINTMENT_REQUEST_SEND_RESULT,

    CONTACT_REQUEST_PERFORMED,

    REAL_TIME_CONNECTED,
    REAL_TIME_DISCONNECTED,

    SHOW_FILTER_DIALOG,
    HIDE_FILTER_DIALOG,
    FILTER_TOP_CAT_SELECTED,
    FILTER_CATEGORY_TOGGLE,
    FILTER_RESET,

    SHOW_NOTE_MODAL,
    HIDE_NOTE_MODAL,
    NOTE_SAVED,
    GET_NOTE,
    EXPORT_NOTES,

    SYNOPTIC_AGENDA_TAB_INDEX_UPDATE,
    KEYBOARD_TOGGLED,

    AD_SWAP,

    DOCUMENT_VISIBLE,

    SHOW_FORM_MODAL,
    HIDE_FORM_MODAL,

    KLIPSOLEADS_SET_DISCLAIMER_STATUS,
    KLIPSOLEADS_REGISTER_SUCCESS,
    KLIPSOLEADS_SET_SORTED_BY_COMPANY,
    KLIPSOLEADS_CONTACTS_UPDATED,
    KLIPSOLEADS_SET_SYNC_ONGOING,
    KLIPSOLEADS_RESET_LICENCE,

    BADGE_SCAN_WITHOUT_RESULT,

    TAIGA_SEARCH_ONGOING,
    TAIGA_SEARCH_PERFORMED,
    TAIGA_SEARCH_CLEARED,

    CONTACT_SAVED_TO_DEVICE,
    CONTACTS_SCAN_STARTED,
    CONTACTS_UPDATED,
    CONTACTS_SCAN_UNAUTHORISED,

    CODIFICATIONS_FETCHED,
} from 'src/store/actionTypes'


const SIMULATED_FETCH_LATENCY = 0;

const LOG_PREF = '[actions] ';


/**
 * Navigate forward
 * @type {String}
 */
export const navigate = (pageKey, pageProps, historyAction) => dispatch => {

    // Special case for FilterDialog
    if (pageProps && pageProps.filterEnabled) {
        if (getCurrentState()
                && getCurrentState().pageProps
                && getCurrentState().pageProps.filterEnabled) {

            // Keep only one filter result in history
            historyAction = HISTORY_ACTIONS.REPLACE;
        }
    }
    if (!historyAction) {
        historyAction = HISTORY_ACTIONS.PUSH;
    }

    dispatch({
        type: NAVIGATE,
        pageKey: pageKey,
        options: pageProps,
        historyAction: historyAction,
    });
};

/**
 * Navigate backward
 * @type {String}
 */
export const navigateBack = () => ({
    type: NAVIGATE_BACK,
});

export function navigateToHome() {
    let home = config.getHomePage(getProfile());
    return navigate(home.pageKey, home.props);
}

export const navigateToItems = (items, dataType, parentId, parentDataType) => ({
    type: NAVIGATE_TO_ITEMS,
    items,
    dataType,
    parentId,
    parentDataType,
});


/**
 * Navigation is done
 */
export const hasNavigated = (pageKey, pageProps, previousPageKey) => ({
    type: HAS_NAVIGATED,
    pageKey,
    pageProps,
    previousPageKey,
});


/**
 * Allows to redirect from parameters (e.g from data or ad configuration)
 *
 * @param  {object} params such as
 *                       { page: 'list', dataType: 'exhibitors'|'event_categories'|...}
 *                       { page: 'map', type: 'exhibitors'|..., originalIds: string array}
 *                       { page: pageKey, ...props}
 * @param  {object} actions
 */
export function applyRedirect(params) {

    // Navigate to list
    if (!params.page || params.page === 'list') {
        return navigate(LIST_PAGE_KEY, {
            inputs: [{ dataType : params.dataType }],
        });

    // Show POIs on map
    } else if (params.page === 'map') {
        let dataType = params.type,
            originalIds = params.originalIds;

        if (!dataType || !originalIds || Array.isArray(originalIds) !== true) {
            console.error('Missing lump.link information to show POI.', dataType, originalIds);

            // FIXME: no action?

            return;
        }
        return showAllPoisOnMap({
            [dataType]: originalIds.map(originalId => ({ id: originalId })),
        });

    // Navigate to any other page
    } else {
        let pageKey = params.page;
        // Remove page key param
        let _params = Object.assign({}, params);
        delete _params.page;

        return navigate(pageKey, parseSpecialParameters(_params, pageKey));
    }
};




/**
 * Change language
 * @param  {string} lang
 */
export const setLanguage = lang => {
    let labels = setCurrentLang(lang);
    return {
        type: LANG_CHANGED,
        language: lang,
        labels: labels,
    };
};


/**
 * Data and assets update is about to start
 * @type {String}
 */
export const dataAssetsUpdating = (files, data) => ({
    type: DATA_ASSETS_UPDATING,
    files,
    data,
});

/**
 * @param {array}
 * @param {array}
 */
export const dataAssetsUpdated = (updatedTables, updatedAssets) => ({
    type: DATA_ASSETS_UPDATED,
    tables: updatedTables,
    assets: updatedAssets,
});


/**
 * Updater has initialized, so assets versions are ready to be queried
 */
export const updaterInitialized = () => ({
    type: UPDATER_INITIALIZED,
});


/**
 * MogiGeo has successfully loaded and displayed the map for a dataset
 * @type {String}
 */
export const mapLoaded = () => ({
    type: MAP_LOADED,
});

/**
 * Must reload the map
 */
export const mapReload = () => ({
    type: MAP_RELOAD,
});

/**
 * MogiGeo has successfully loaded and displayed the map for a dataset
 * @type {String}
 */
export const mapIsRestarting = () => ({
    type: MAP_IS_RESTARTING,
});

/**
 * User has a position
 */
export const mapUserLocated = () => ({
    type: MAP_USER_LOCATED,
});

/**
 * User has a no position
 */
export const mapUserUnlocated = () => ({
    type: MAP_USER_UNLOCATED,
});

/**
 * Display an itinerary to a POI
 */
export const showMapItinerary = (start, dest, options) => ({
    type: SHOW_MAP_ITINERARY,
    start: start,
    dest: dest,
    options: options
});


/**
 * dispatch an itinerary to a POI
 */
export const dispatchItinerary = (poi) => ({
    type: DISPATCH_ITINERARY,
    poi: poi
});


/**
 * Display a modal allowing the search for a POI (to show on map or to compute a route)
 */
export const showSearchPlaceDialog = type => ({
    type: SHOW_SEARCH_PLACE_DIALOG,
    searchType: type,
});

/**
 * Hide the modal allowing the user to choose start/destination
 */
export const hideSearchPlaceDialog = () => ({
    type: HIDE_SEARCH_PLACE_DIALOG,
});

// @see searchPlaceDialogMiddleware
export const setSearchPlaceDialogCancelable = value => ({
    type: SET_SEARCH_PLACE_DIALOG_CANCELABLE,
    value: value,
});

/**
 * Show POIs on map
 *
 * NB: used to be a specific action `SHOW_ALL_POIS_ON_MAP`.
 * Now that the url must be aware of the POI to show, `navigate` action is used
 */
export const showAllPoisOnMap = pois => navigate(MAP_PAGE_KEY, { pois: pois });

/**
 * Show a single POI on map
 *
 * NB: used to be a specific action `SHOW_ONE_POI_ON_MAP`.
 * Now that the url must be aware of the POI to show, `navigate` action is used
 */
export const showOnePoiOnMap = poi => navigate(MAP_PAGE_KEY, { poi: poi });

/**
 * Set custom POI styles on map
 * @param  {array} data
 */
export const showCustomPoiStateOnMap = data => navigate(MAP_PAGE_KEY, { customPoiState: data });

/**
 * Route to map to focus on a specific zone and floor
 */
export const mapZoomOnZone = (zone, floor) => ({
    type: MAP_ZOOM_ON_ZONE,
    zone: zone,
    floor: floor,
});


export const itineraryApiCalled = destination => ({
    type: ITINERARY_API_CALLED,
    destination: destination,
});

export const mobigeoErrorThrown = (errorCode, mobigeoModuleName, additionalInfo) => ({
    type: MOBIGEO_ERROR_THROWN,
    errorCode: errorCode,
    module: mobigeoModuleName,
    additionalInfo: additionalInfo,
});

export const mapFavoriteCreated = () => ({
    type: MAP_FAVORITE_CREATED,
});

export const mapFavoriteShared = () => ({
    type: MAP_FAVORITE_SHARED,
});

export const setCodeIdentification = code => ({
    type: SET_CODE_IDENTIFICATION,
    code
});

export const setTemporaryCodeIdentification = code => ({
    type: SET_TEMPORARY_CODE_IDENTIFICATION,
    code
});

export const showFavoritesCodeDialog = () => ({
    type: SHOW_FAVORITES_CODE_DIALOG
});

export const hideFavoritesCodeDialog = () => ({
    type: HIDE_FAVORITES_CODE_DIALOG
});

export const fetchFavoritesCode = () => ({
    type: FETCH_FAVORITES_CODE
});

export const syncWithFavoritesCode = () => ({
    type: SYNC_WITH_FAVORITES_CODE
});

export const setFavoritesCodeSyncStep = (step) => ({
    type: SET_FAVORITES_CODE_SYNC_STEP,
    step
});

/**
 * GEOGROUP actions
 */

export const geogroupPseudoSet = user => ({
    type: GEOGROUP_PSEUDO_SET,
    user: user,
});

export const geogroupGroupCreated = group => ({
    type: GEOGROUP_GROUP_CREATED,
    group: group,
});

export const geogroupGroupJoined = group => ({
    type: GEOGROUP_GROUP_JOINED,
    group: group,
});

export const geogroupGroupQuitted = group => ({
    type: GEOGROUP_GROUP_QUITTED,
    group: group,
});

export const toggleLocationStatus = value => ({
    type : TOGGLE_LOCATION_STATUS,
    value: value,
});



export const requestLocationCapabilities = () => ({
    type : REQUEST_LOCATION_CAPABILITIES
});

export const stopLocation = () => ({
    type : STOP_LOCATION
});



export const toggleLocationCapabilityStatus = value => {
    return ({
        type : TOGGLE_LOCATION_CAPABILITY_STATUS,
        value: value,
    });
};

export const togglePMRStatus = value => ({
    type : TOGGLE_PMR_STATUS,
    value: value,
});


/**
 * Handle what happens when redirecting to an item
 * (show POI, or display dedicated page e.g ExhibitorPage)
 *
 * @param  {string} type
 * @param  {number} id
 * @param  {string} originalId
 */
export function genericItemNavigation(type, id, originalId) {
    if  (shouldRedirectToMap(type)) {
        return showOnePoiOnMap({ type, id, originalId });
    } else {
        return navigate(DATA_TYPE_TO_PAGE_KEY[type], { id: /^\d+$/.test(id) ? parseInt(id, 10) : id, });
    }
}


/**
 * Generic behaviour when a category is clicked
 * @param  {number} id
 * @param  {string} catDataType
 * @return {object}
 */
export function categoryClicked(id, catDataType) {

    let cat = Query.get(id, catDataType);

    let itemsDataType = CATEGORIES_MAPPING[catDataType],
        childrenIds = cat.lump ? cat.lump[itemsDataType] : null,
        childrenLength = Array.isArray(childrenIds) ? childrenIds.length : 0,
        hasFilter = cat.lump ? cat.lump.hasFilter : null;


    // Special case: Ability to redirect to another page
    if (cat.lump && cat.lump.link) {
        return applyRedirect(cat.lump.link);
    }

    if (childrenLength > 0) {
        if (childrenLength === 1 && catDataType !== DATA_TYPE_BRAND_CATEGORIES) {
            // Cat countains a single item: directly display the item
            return genericItemNavigation(itemsDataType, childrenIds[0], null);

        } else {
            // Display children from this category
            if (catDataType === DATA_TYPE_EVENT_CATEGORIES) {
                // Show a list of events grouped by date
                return navigate(LIST_GROUPS_PAGE_KEY, {
                    input: {
                        parentId: id,
                        parentType: catDataType,
                        dataType: DATA_TYPE_EVENTS,
                    },
                });
            }
            else {
                return navigate(LIST_PAGE_KEY, {
                    locateAll: cat.lump.locateAll === 'true',
                    hasFilter: hasFilter,
                    inputs: [{
                        parentId: id,
                        parentType: catDataType,
                        dataType: itemsDataType,
                    }],
                });
            }
        }
    } else {
        // Special 'is_all' category
        if (cat.is_all) {
            if (catDataType === DATA_TYPE_EVENT_CATEGORIES) {
                return navigate(LIST_GROUPS_PAGE_KEY, {
                    input: { dataType: DATA_TYPE_EVENTS },
                });
            } else {
                return navigate(LIST_PAGE_KEY, {
                    hasFilter: hasFilter,
                    inputs: [{
                        dataType : itemsDataType,
                    }],
                });
            }

        } else {
            // Display sub-categories
            return navigate(LIST_PAGE_KEY, {
                hasFilter: hasFilter,
                inputs: [{
                    id: id,
                    dataType: catDataType,
                }]
            });
        }
    }
}



const itemFetchStatus = props => ({
    type: ITEM_FETCHED,
    isFavorite: Favorites.isFavorite(props.id, props.dataType),
    ...props
});

export const itemBeingFetched = (id, dataType) => ({
    type: ITEM_BEING_FETCHED,
    id,
    dataType,
})

let lastFetchedItem;
/**
 * Fetch a single item
 * @param  {number} id
 * @param  {string} dataType
 * @param  {array} relatedDataToSet
 * @return {object/promise}
 */
export const fetchItem = (id, dataType, relatedDataToSet) => dispatch => {
    let status,
        item;

    function next() {
        dispatch(itemFetchStatus({ id: id, dataType: dataType, item: item, status: status }));
    }

    // Check dataType
    if (VALID_DATA_TYPES.indexOf(dataType) === -1) {
        console.error(LOG_PREF + 'Cannot fetch data for type: ' + dataType);
        status = STATUS.NO_RESULT;
        next();
        return;
    }

    window.setTimeout(() => {
        if (Db.isDataReady() !== true) {
            status = STATUS.PENDING;
        } else {
            let _relatedDataToSet = relatedDataToSet ? relatedDataToSet : Pages[DATA_TYPE_TO_PAGE_KEY[dataType]].relatedDataToFetch;

            if (SINGLE_ITEM_DATATYPES.indexOf(dataType) !== -1) {
                item = Db.getSortedAndTransformedData()[dataType][0];
                if (_relatedDataToSet) {
                    item = Query.completeData(item, dataType, _relatedDataToSet);
                }
            } else {
                item = Query.get(id, dataType, _relatedDataToSet);
            }

            if (item) {
                // LOCAL DATA FOUND
                status = STATUS.FETCHED;
                lastFetchedItem = { id, dataType, };

                // Fetch any additional needed data from web service call
                switch (dataType) {

                    case DATA_TYPE_EXHIBITORS:
                        if (isSessionValid()
                                && config.NETWORKING
                                && config.NETWORKING.FEATURE_ENABLED
                                && config.NETWORKING.FETCH_EXHIBITOR_PARTICIPANTS) {

                            // Get related contacts
                            let references = item.references || {};
                            references[DATA_TYPE_PARTICIPANTS] = { status: STATUS.PENDING };
                            item.references = references;

                            ParticipantsService.getParticipantsRelatedToAnExhibitor(
                                item.original_id,
                                function({ error, data }) {
                                    if (id !== lastFetchedItem.id || dataType !== lastFetchedItem.dataType) {
                                        // If item has changed during the web service call, skip WS response
                                        return;
                                    }
                                    references[DATA_TYPE_PARTICIPANTS] = error ? [] : data;
                                    item = Object.assign({}, item, references);
                                    next();
                                }
                            );
                        }
                        break;

                    case DATA_TYPE_SPEAKERS:
                        if (isSessionValid()
                                && config.NETWORKING
                                && config.NETWORKING.FEATURE_ENABLED
                                && config.NETWORKING.FETCH_SPEAKER_PARTICIPANT) {

                            // Get participant data
                            let references = item.references || {};

                            // Local fetch
                            let localData = Query.get(item.original_id, DATA_TYPE_PARTICIPANTS);
                            if (localData) {
                                item.references.participant = localData;
                            } else {
                                references.participant = { status: STATUS.PENDING };
                            }

                            // Remote fetch to refresh the participant data
                            ParticipantsService.getParticipantsByIds(
                                [ item.original_id ],
                                function({ error, data }) {
                                    if (id !== lastFetchedItem.id || dataType !== lastFetchedItem.dataType) {
                                        // If item has changed during the web service call, skip WS response
                                        return;
                                    }
                                    if (error || !data || Array.isArray(data) !== true || data.length === 0) {
                                        references.participant = null;
                                    } else {
                                        references.participant = data[0];
                                    }
                                    item = Object.assign({}, item, references);
                                    next();
                                }
                            );
                        }
                        break;

                    default: // for linter
                }
            } else {
                status = STATUS.NO_RESULT;
            }

            // Fetch data from remote
            if (dataType === DATA_TYPE_PARTICIPANTS) {
                if (!item) {
                    // No local data found, in that case keep 'pending' status
                    status = STATUS.PENDING;
                }

                ParticipantsService.search({ ids: [ id ]}, function({ error, data }) {
                    if (error
                            || !data
                            || Array.isArray(data) !== true
                            || data.length !== 1) {
                        status = STATUS.NO_RESULT;
                    } else {
                        item = data[0];
                        status = STATUS.FETCHED;
                    }
                    next();
                });
            }
        }

        next();
    }, SIMULATED_FETCH_LATENCY);
};


/* `fetchItems` DOESN'T SEEM USED ANYMORE (strangely)

const itemsFetchStatus = (ids, dataType, items, status) => ({
    type: ITEMS_FETCHED,
    ids: ids,
    dataType: dataType,
    items: items,
    status: status,
    favorites: Favorites.getAll(),
});

**
 * @param  {array|string} ids (string if there is a single id)
 * @param  {string} dataType
 * @param  {array} relatedDataToSet
 * @return {object/promise}
 *
export const fetchItems = (ids, dataType, relatedDataToSet) => dispatch => {
    let status,
        items;

    // Check dataType
    if (VALID_DATA_TYPES.indexOf(dataType) === -1) {
        console.error(LOG_PREF + 'Cannot fetch data for type: ' + dataType);
        dispatch(itemsFetchStatus(ids, dataType, items, STATUS.NO_RESULT));
        return;
    }

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;
    } else {
        let _ids;
        // Handle various input type
        if (Array.isArray(ids)) {
            _ids = ids;
        } else if (typeof ids === 'string') {
            _ids = ids.split(',');
        } else if (typeof ids === 'number') {
            _ids = [ids];
        } else {
            console.error(LOG_PREF + 'Unexpected type of argument `ids`', ids);
            _ids = [];
        }
        items = _ids.map((id) => Query.get(id, dataType, relatedDataToSet)).filter(item => item);

        if (items.length > 0) {
            items = Db.sortItems(items, dataType);
            status = STATUS.FETCHED;
        } else {
            status = STATUS.NO_RESULT;
        }
    }

    window.setTimeout(() => {
        dispatch(itemsFetchStatus(ids, dataType, items, status));
    }, SIMULATED_FETCH_LATENCY);
};*/


const listsFetchStatus = (inputs, items, status, contextualTitle, header, customStateOnMap) => ({
    type: LISTS_FETCHED,
    inputs: inputs,
    items: items,
    status: status,
    favorites: Favorites.getAll(),
    contextualTitle: contextualTitle,
    header: header,
    customStateOnMap: customStateOnMap,
});

/**
 * Fetch a list of items
 * @param  {object} inputs @see ListPage propTypes for description
 * @return {array/promise}
 */
export const fetchLists = inputs => dispatch => {

    let promises = (inputs || []).map(input => new Promise(function(resolve, reject) {

        // Special case: participants (fetch them using a web service)
        if (input.dataType === DATA_TYPE_PARTICIPANTS) {
            ParticipantsService.getParticipants(function(error, participants) {
                if (error) {
                    console.error('Failed to fetch participants', error);
                    resolve({
                        dataType: input.dataType,
                        status: STATUS.NO_RESULT,
                        items : [],
                    });
                } else {
                    let visibleParticipants = participants.filter(p => p.visible);
                    resolve({
                        dataType: input.dataType,
                        status: STATUS.FETCHED,
                        items : Db.sortItems(visibleParticipants, input.dataType),
                    });
                }
            });

            // PBC-111 - Also refresh user data
            // (e.g if participants have been contacted from another device
            // during the FULL_PARTICIPANTS_DATA_EXPIRE_DELAY)
            UserDataService.refreshUserDataFromAPI();

            return;
        }


        let result;
        if (NON_CATEGORIES_DATA_TYPE.indexOf(input.dataType) !== -1) {

            if (input.parentId !== null && typeof input.parentId !== 'undefined') {
                // Fetch from parent category
                result = fetchContentOfCategory(input.parentId, input.parentType, input.dataType);
            }
            else if (Array.isArray(input.ids)) {
                result = {
                    items: input.ids.map(id => Query.get(id, input.dataType)).filter(item => item),
                    dataType: input.dataType,
                    status: STATUS.FETCHED,
                };
            }
            else {
                // Fetch all
                result = fetchAll(input.dataType);
            }

        } else if (CATEGORIES_DATA_TYPE.indexOf(input.dataType) !== -1) {
            result = fetchSubCategories(input.id, input.dataType);

        } else {
            // fatal error
            console.error(LOG_PREF + 'Cannot fetch data for type: ' + input.dataType);
            reject();
        }

        /*
        status[input.dataType] = result.status;
        items[input.dataType] = Db.sortItems(result.items, input.dataType);

        if (result.contextualTitle) {
            contextualTitles.push(result.contextualTitle);
        }
        header = result.header;
        customStateOnMap = result.customStateOnMap;*/

        resolve({
            dataType: input.dataType,
            status: result.status,
            items : Db.sortItems(result.items, input.dataType),
            contextualTitle: result.contextualTitle,
            header: result.header,
            customStateOnMap: result.customStateOnMap,
        });
    }));

    Promise.all(promises).then(results => {
        let status = {},
            items = {},
            contextualTitles = [],
            header,
            customStateOnMap;

        results.forEach(result => {
            status[result.dataType] = result.status;
            items[result.dataType] = result.items;
            header = result.header;
            customStateOnMap = result.customStateOnMap;

            if (result.contextualTitle) {
                contextualTitles.push(result.contextualTitle);
            }
        });

        dispatch(listsFetchStatus(inputs, items, status, contextualTitles.join(' & '), header, customStateOnMap));
    });
}

function fetchAll(dataType) {
    let status,
        items;

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;

    } else {
        items = Query.getAll(dataType);

        if (!items || items.length === 0) {
            status = STATUS.NO_RESULT;
        } else {
            status = STATUS.FETCHED;
        }
    }
    return {
        items: items,
        status: status,
        dataType: dataType,
    };
}

function fetchContentOfCategory(parentId, parentType, childrenDataType) {
    let status,
        items,
        contextualTitle,
        header,
        ad,
        customStateOnMap,
        hasSynopticAgendaButton;

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;

    } else {
        // Get parent
        let parent = Query.get(parentId, parentType);

        if (parent) {

            contextualTitle = parent.title;
            header = parent.lump ? parent.lump.header : null;
            ad = parent.lump ? parent.lump.ad : null;
            customStateOnMap = parent.lump ? parent.lump.customStateOnMap : null;
            hasSynopticAgendaButton = parent.lump.hasSynopticAgendaButton;

            // Get children ids
            let ids = parent.lump[childrenDataType];
            if (Array.isArray(ids)) {

                // Get items
                items = ids.map(id => Query.get(id, childrenDataType));

                // Safety net: remove items not found
                items = items.filter(item => item);

                // Sort items
                items = Db.sortItems(items, childrenDataType);
            }
        }

        if (Array.isArray(items) !== true || items.length === 0) {
            status = STATUS.NO_RESULT;
        } else {
            status = STATUS.FETCHED;
        }
    }

    return {
        items: items,
        status: status,
        dataType: childrenDataType,
        contextualTitle: contextualTitle,
        header: header,
        ad: ad,
        customStateOnMap: customStateOnMap,
        hasSynopticAgendaButton: hasSynopticAgendaButton,
    };
}


/**
 * Fetch a category's content
 * @param  {number} id
 * @param  {string} dataType
 * @return {array/promise}
 */
function fetchSubCategories(id, dataType) {
    let status,
        items,
        contextualTitle,
        header;

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;

    } else if (!Db.getData()[dataType] || Db.getData()[dataType].length === 0) {
        status = STATUS.NO_RESULT;

    } else {
        let subCategories;
        let siblingItems;

        const siblingDatatype = CATEGORIES_MAPPING[dataType];

        if (id === null || typeof id === 'undefined' || id === '') {
            // Get top categories
            items = Query.getTopCategories(dataType);
            // siblingItems = Query.find([sibling => (!sibling.lump.cats || (Array.isArray(sibling.lump.cats) && sibling.lump.cats.length === 0))], siblingDatatype);

        } else {
            // Get sub-catagories of a category
            let category = Query.get(id, dataType);

            if (category) {
                contextualTitle = category.title;
                header = category.lump.header;
                subCategories = category.lump.cats;
                const categorySiblings = category.lump[siblingDatatype]

                if (Array.isArray(subCategories) === true && subCategories.length > 0) {
                    items = subCategories.map(catId => Query.get(catId, dataType));
                }

                if (Array.isArray(categorySiblings) === true && categorySiblings.length > 0) {
                    siblingItems = categorySiblings.map(siblingId => Query.get(siblingId, siblingDatatype));
                }

            }
        }

        // Ignore empty categories
        if (Array.isArray(items)) {
            items = items.filter(item => item && item.counter > 0);
        }

        if (Array.isArray(siblingItems)) {

            // Safety net: remove items not found
            siblingItems = siblingItems.filter(sibl => sibl);

            if (siblingItems.length > 0) {
                items = items || []
                items = items.concat(siblingItems.map(siblingItem => ({ ...siblingItem, listType: siblingDatatype })));
            }
        }

        if (Array.isArray(items) !== true || items.length === 0) {
            status = STATUS.NO_RESULT;
        } else {
            status = STATUS.FETCHED;
        }
    }

    return {
        items,
        status,
        dataType,
        contextualTitle,
        header,
    };
}



export const groupedItemsFetched = (input, groupedItems, status, dataType, contextualTitle, ad, hasSynopticAgendaButton) => ({
    type: GROUPED_ITEMS_FETCHED,
    input,
    groupedItems,
    dataType,
    status,
    ad,
    contextualTitle,
    hasSynopticAgendaButton,
    favorites: Favorites.getAll(),
});

export const fetchGroupedItems = (input, relatedDataToSet) => dispatch => {
    let status,
        contextualTitle,
        ad,
        items,
        groupedItems,
        hasSynopticAgendaButton,
        dataType = input.dataType;

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;

    } else if (!Db.getData()[dataType] || Db.getData()[dataType].length === 0) {
        status = STATUS.NO_RESULT;

    } else {
        let parentId;
        if (input && input.parentId) {
            parentId = typeof input.parentId === 'number' ? input.parentId : parseInt(input.parentId, 10);
        }

        if (!input || !parentId) {
            // Check synoptic button presence flag
            let allCat = Query.findOne([ cat => !!cat.is_all ], Db.getCategoryDatatype(dataType));
            if (allCat) {
                hasSynopticAgendaButton = allCat.lump.hasSynopticAgendaButton;
            }

            // Get all items
            items = Query.getAll(dataType);
        } else {
            // Get items belonging to a specific category
            let result = fetchContentOfCategory(parentId, input.parentType, dataType);
            items = result.items;
            contextualTitle = result.contextualTitle;
            ad = result.ad;
            // Synoptic button presence flag:
            hasSynopticAgendaButton = result.hasSynopticAgendaButton;
        }

        if (items.length > 0) {
            if (Array.isArray(relatedDataToSet)) {
                items = items.map(item => Query.completeData(item, DATA_TYPE_EVENTS, relatedDataToSet));
            }

            // Execute group configuration
            groupedItems = groupItems(items, dataType);

            status = STATUS.FETCHED;
        } else {
            status = STATUS.NO_RESULT;
        }
    }

    dispatch(groupedItemsFetched(input, groupedItems, status, dataType, contextualTitle, ad, hasSynopticAgendaButton));
};


export const profileChanged = newProfile => ({
    type: PROFILE_CHANGED,
    profile: newProfile,
});

export const showDisclaimer = () => ({
    type: SHOW_DISCLAIMER,
});

export const showChooseLangDialog = () => ({
    type: SHOW_LANG_DIALOG,
});
export const hideChooseLangDialog = () => ({
    type: HIDE_LANG_DIALOG,
});

export const startLogin = (username, password) => ({
    type: PERFORM_LOGIN,
    username: username,
    password: password,
});

export const setLoginStatus = (loggedIn, userData, error) => ({
    type: SET_LOGIN_STATUS,
    loggedIn,
    userData,
    error,
});

export const updateUserDataRequestStatus = status => ({
    type: UPDATE_USER_DATA_REQUEST_STATUS,
    status,
})

export const userDataUpdated = userData => ({
    type: USER_DATA_UPDATED,
    userData: userData,
});

export const remoteSaveUserData = userData => dispatch => {
    UserDataService.remoteSaveUserData(userData, true, function(error) {
        if (error) {
            // FAILURE
            if (error === WS_ERRORS.AUTH) {
                setTimeout(() => {
                    LoginService.setLoginStatus(false);
                    dispatch(navigate(LOGIN_PAGE_KEY, { nextRoute: { pageKey: USER_DATA_PAGE_KEY }}));
                }, 2000);
            }
            dispatch(updateUserDataRequestStatus({ saving: false, error: error, }));
        }
        else {
            // SUCCESS
            // No need to:
            //  - show a notification (UserDataForm does it)
            //  - dispatch a status update (at lower level user data is automatically refreshed which ends updating `status`)
        }
    });
    dispatch(updateUserDataRequestStatus({ saving: true, error: null, }));
}

export const updatePageState = (pageKey, props) => ({
    type: UPDATE_PAGE_STATE,
    pageKey: pageKey,
    props: props,
});

export function toggleFavorite(id, dataType, isFav, noSync = false, source, data) {
    if (isFav) {
        // Removing an item from favorites
        // Check if it is declared in user data
        if (UserDataService.isItemRelatedToUserData(id, dataType)) {
            return showNotification({
                message: getLabels().userData.cantRemoveItemFromFavorites,
            });
        }
    }

    Favorites.toggle(id, dataType, isFav);

    return ({
        type: TOGGLE_FAVORITE,
        id,
        dataType,
        favListUpdated: true, // forced to true due to 'synchronize favorites' feature
        isFav,
        noSync,
        source,
        data,
        favorites: JSON.parse(JSON.stringify(Favorites.getAll())), // clone
    });
}


export const allFavoritesDeleted = () => ({
    type: ALL_FAVORITES_DELETED,
});


export function fetchFavorites() {
    let favorites = Favorites.getAll();

    // Merge mobigeo favorite positions into app favorites
    let favoritePositions = MobiGeo.Favorite.getAll() || [];
    favorites[DATA_TYPE_FAVORITE_POSITIONS] = favoritePositions.map(fav => fav.id);

    // Get full data for each entry
    let status,
        data = {};

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;
    } else {

        function getItem(id, dataType) {
            if (dataType === DATA_TYPE_FAVORITE_POSITIONS) {
                return favoritePositions.find(fav => fav.id === id);
            } else {
                return Query.get(id, dataType);
            }
        }

        Object.keys(favorites).forEach(dataType => {
            if (VALID_DATA_TYPES.indexOf(dataType) !== -1) {

                data[dataType] = [];
                favorites[dataType].forEach(id => {
                    let entry = getItem(id, dataType);
                    if (!entry) {
                        console.log('Could not find entry ' + id + ' of type: ' + dataType);
                    } else {
                        data[dataType].push(entry);
                    }
                });

                // Sort
                data[dataType] = Db.sortItems(data[dataType], dataType);
            }
        });
        status = STATUS.FETCHED;
    }
    return {
        type: FETCH_FAVORITES,
        favorites: JSON.parse(JSON.stringify(favorites)),
        data: data,
        status: status,
    };
};



export const setFavoritesSynchronizationStatus = status => ({
    type: SET_FAVORITES_SYNCHRONIZATION_STATUS,
    status,
});

export const synchroFavoritesIconClicked = active => ({
    type: SYNCHRO_FAVORITES_ICON_CLICKED,
    active
});


export const windowResized = () => ({
    type: WINDOW_RESIZED,
    timestamp: new Date().getTime(),
});


function getSearchType(pageKey, value) {
    if (pageKey !== SEARCH_PAGE_KEY) {

        if (value.length === 1) {
            return SEARCH_TYPES.STARTS_WITH;
        }
        else if (value.length === 2) {
            return SEARCH_TYPES.WORD_STARTS_WITH;
        }
    }
}

// Search through a single data type
export function searchOnASingleDataType(value, pageKey, dataType, data) {
    let { results, count } = applySearchOnSingleDataType(
        dataType,
        data,
        value,
        getSearchType(pageKey, value)
    );
    return {
        type: SEARCH_PERFORMED,
        pageKey: pageKey,
        searched: value,
        status: STATUS.FETCHED,
        results: { [dataType]: results },
        totalCount: count,
        favorites: Favorites.getAll(),
        contextualSearch: false
    };
}

// Search
export function performSearch(value, pageKey, dataTypes, contextualSearch) {

    let searchDataTypes = null
    let searchResult;

    // add children sibling type if category type
    if (dataTypes && Array.isArray(dataTypes) && dataTypes.length > 0) {
        searchDataTypes = [...dataTypes]
        searchDataTypes.forEach(dataType => {
            if (NON_CATEGORIES_DATA_TYPE.indexOf(dataType) === -1) {
                const siblingType = CATEGORIES_MAPPING[dataType]
                if (searchDataTypes.indexOf(siblingType) === -1 && VALID_DATA_TYPES.indexOf(siblingType) > -1) {
                    searchDataTypes.push(siblingType)
                }
            }

            if (NON_CATEGORIES_DATA_TYPE.indexOf(dataType) > -1) {
                const catTypes = Object.keys(CATEGORIES_MAPPING)
                const siblingCatType = catTypes.find(catType => CATEGORIES_MAPPING[catType] === dataType)
                if (siblingCatType && searchDataTypes.indexOf(siblingCatType) === -1  && VALID_DATA_TYPES.indexOf(siblingCatType) > -1) {
                    searchDataTypes.push(siblingCatType)
                }
            }
        })
    }

    let searchType = getSearchType(pageKey, value);

    // PERFORM
    searchResult = search(value, searchDataTypes, searchType);

    // clean results
    const resultTypes = Object.keys(searchResult.data);
    let data = {}
    resultTypes.forEach(dataType => {
        let _items = searchResult.data[dataType] || []

        // category type
        if (NON_CATEGORIES_DATA_TYPE.indexOf(dataType) === -1) {
            const siblingType = CATEGORIES_MAPPING[dataType]
            _items = _items.filter(item => item.lump
                && ((item.lump[siblingType] && item.lump[siblingType].length > 0)
                    || (item.lump.cats && item.lump.cats.length > 0)))
        }

        if (_items.length > 0) {
            data[dataType] = _items
        }
    })
    return {
        type: SEARCH_PERFORMED,
        pageKey: pageKey,
        searched: value,
        status: searchResult.status,
        results: data,
        totalCount: searchResult.totalCount,
        favorites: Favorites.getAll(),
        contextualSearch: contextualSearch
    };
}

export const clearSearchResults = pageKey => ({
    type: CLEAR_SEARCH_RESULTS,
    pageKey,
})

export const setSearchFieldVisible = (pageKey, isVisible) => ({
    type: SET_SEARCH_FIELD_VISIBLE,
    pageKey,
    isVisible,
})


// Place search
export function performPlaceSearch(string, searchType) {
    const searchResult = searchPlaces(string);
    return {
        type: PLACE_SEARCH_PERFORMED,
        searched: string,
        searchType: searchType,
        status: searchResult.status,
        results: searchResult.data,
        totalCount: searchResult.totalCount,
    };
};

export function searchedPlaceSelected(searchType, entry) {
    if (!entry.text) {
        let _elementPropsGetters = elementPropsGetters(entry.type),
            item = Query.get(entry.id, entry.type);
        if (item && _elementPropsGetters) {
            entry.text = _elementPropsGetters.text(item);

            // textMinor is not mandatory
            if (typeof _elementPropsGetters.textMinor === 'function') {
                entry.textMinor = _elementPropsGetters.textMinor(item);
            }
        }
    }
    return {
        type: SEARCHED_PLACE_SELECTED,
        searchType: searchType,
        entry: entry,
    };
}

export const clearPlaceResults = type => ({
    type: CLEAR_PLACE_RESULTS,
    status: STATUS.FETCHED,
    searchType: type,
});



function toggleMenu(pageKey, isOpen) {
    if (!pageKey || typeof pageKey !== 'string') {
        console.error(LOG_PREF + 'Cannot ' + (isOpen ? 'open' : 'close') + ' menu, missing page key', pageKey);
        return;
    }
    return {
        type: TOGGLE_MENU,
        pageKey: pageKey,
        isOpen: isOpen,
    };
};
export const openMenu = pageKey => toggleMenu(pageKey, true);
export const closeMenu = pageKey => toggleMenu(pageKey, false);


export function showDataListDialog(idsByType, placeId, pageKey) {

    let items = {};
    Object.keys(idsByType).forEach(dataType => {
        items[dataType] = idsByType[dataType].map(id => Query.get(id, dataType)).filter(itm => itm);
        items[dataType].forEach(item => {
            item.contextualPlaceId = placeId;
        });
        items[dataType] = Db.sortItemsForDataListDialog(items[dataType], dataType);
    });

    let title, place;
    if (typeof placeId === 'number') {
        place = Query.get(placeId, DATA_TYPE_PLACES);
        if (place) {
            title = place.label;
        }
    }

    return {
        type: SHOW_DATA_LIST_DIALOG,
        items: items,
        placeId: placeId,
        title: title,
        pageKey: pageKey,
        favorites: Favorites.getAll(),
    };
};


export const hideDataListDialog = () => ({
    type: HIDE_DATA_LIST_DIALOG,
});

export const showShareDialog = (name, description, url, image) => ({
    type: SHOW_SHARE_DIALOG,
    name,
    description,
    url,
    image
});


export function fetchNotes() {
    let notes = Notes.getAll();


    // Get full data for each entry
    let status,
        data = {};

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;
    } else {

        function getItem(id, dataType) {
            return Query.get(id, dataType);
        }
        Object.keys(notes).forEach(dataType => {
            if (VALID_DATA_TYPES.indexOf(dataType) !== -1) {
                data[dataType] = [];
                notes[dataType].forEach(id => {
                    let entry = getItem(id, dataType);
                    if (!entry) {
                        console.warn('Could not find entry ' + id + ' of type: ' + dataType);
                    } else {
                        data[dataType].push(entry);
                    }
                });
                // Sort
                data[dataType] = Db.sortItems(data[dataType], dataType);
            }
        });
        status = STATUS.FETCHED;
    }
    return {
        type: FETCH_NOTES,
        notes: notes,
        data: data,
        status: status,
    };
};

export const saveNote = (itemTitle, itemId, note, dataType) => dispatch => {
    Notes.saveNote(itemTitle, itemId, note, dataType);
    dispatch({
        type: NOTE_SAVED,
        note: note,
        notes: Notes.getAll(),
    })
};

export function getNote(itemId, dataType) {
    let note = Notes.getNote(itemId, dataType);
    return {
        type: GET_NOTE,
        note: note
    };
};

export const deleteNote = (itemId, dataType) => dispatch => {
    Notes.deleteNote(itemId, dataType);
    dispatch(fetchNotes())
    dispatch(hideNoteModal());
};


export const exportNotes = (notes, notesData) => dispatch => {
    dispatch({
        type: EXPORT_NOTES,
        notes: notes,
        notesData: notesData
    });
};


export const showNoteModal = (itemTitle, itemId, dataType, liEl) => dispatch => {
    itemId = typeof itemId === "string" ? parseInt(itemId, 10) : itemId
    dispatch(getNote(itemId, dataType));
    dispatch({
        type: SHOW_NOTE_MODAL,
        itemTitle,
        itemId,
        dataType,
        liEl
    });
};
export const hideNoteModal = () => ({
    type: HIDE_NOTE_MODAL,
});


export const showFormModal = (form, formAction, formActionPayload, liEl) => dispatch => {
    dispatch({
        type: SHOW_FORM_MODAL,
        form,
        formAction,
        formActionPayload,
        liEl
    });
};
export const hideFormModal = () => ({
    type: HIDE_FORM_MODAL,
});



export const hideShareDialog = () => ({
    type: HIDE_SHARE_DIALOG,
});


export const saveContactToDevice = (item) => dispatch => {
    Contacts.saveContactToDevice(item);
    dispatch({
        type: CONTACT_SAVED_TO_DEVICE
    })
};

export function showFilterDialog(dataTypes) {

    // Only one data type is handled for now
    let catDataType = Db.getCategoryDatatype(dataTypes[0]);

    let topCats = Query.getSystemCategories(catDataType);
    if (Array.isArray(topCats)) {
        // Remove the "All items" category, and any empty top categories
        topCats = topCats.filter(cat => !cat.is_all && cat.counter > 0);
        // Sort
        Db.sortItems(topCats, catDataType);
    } else {
        topCats = [];
    }

    return {
        type    : SHOW_FILTER_DIALOG,
        topCats : topCats,
        dataType: catDataType,
    };
}

export const hideFilterDialog = () => ({
    type: HIDE_FILTER_DIALOG,
});

export const filterTopCatSelected = (catId, dataType) => ({
    type: FILTER_TOP_CAT_SELECTED,
    catId: catId,
    dataType: dataType,
})

export const filterCategoryToggle = (catId, dataType, toRemove) => ({
    type: FILTER_CATEGORY_TOGGLE,
    catId,
    dataType,
    toRemove,
});

export const filterReset = () => ({
    type: FILTER_RESET,
})

export const configJsonLoaded = () => ({
    type: CONFIG_JSON_LOADED,
});

export const pollConfigUpdated = () => ({
    type: POLL_CONFIG_UPDATED,
})

export const pollConfigLoaded = pollConfig => ({
    type: POLL_CONFIG_LOADED,
    pollConfig: pollConfig,
});

export const validatePollCode = (poll_id, poll_code) => ({
    type: VALIDATE_POLL_CODE,
    poll_id,
    poll_code
})

export const validatePoll = (poll_id, poll_code) => ({
    type: VALIDATE_POLL,
    poll_id,
    poll_code
})

export const setPollId = (poll_id) => ({
    type: SET_POLL_ID,
    poll_id
})

export const setPollCode = (poll_code) => ({
    type: SET_POLL_CODE,
    poll_code
})

export const showPollDialog = (poll_id) => ({
    type: SHOW_POLL_DIALOG,
    poll_id
});

export const hidePollDialog = () => ({
    type: HIDE_POLL_DIALOG
});

export const goToNextPollStep = (question_id) => ({
    type: GO_TO_NEXT_POLL_STEP,
    question_id
});

export const goToPreviousPollStep = (question_id) => ({
    type: GO_TO_PREVIOUS_POLL_STEP,
    question_id
});

export const submitPoll = (poll_id) => ({
    type: SUBMIT_POLL,
    poll_id
})

export const setPollStep = (poll_id, question_id, data) => ({
    type: SET_POLL_STEP,
    poll_id,
    question_id,
    data
})

export const setPollError = (error) => ({
    type: SET_POLL_ERROR,
    error
})

export const setPollPage = (page) => ({
    type: SET_POLL_PAGE,
    page
})

export const setPollMark = (value) => ({
    type: SET_POLL_MARK,
    value
})

export const setPollComment = (value) => ({
    type: SET_POLL_COMMENT,
    value
})

export const setPollChoice = (value) => ({
    type: SET_POLL_CHOICE,
    value
})

export const setPollMultiple = (value) => ({
    type: SET_POLL_MULTIPLE,
    value
})

export const fetchContributionsFeed = (userAction) => ({
    type: FETCH_CONTRIBUTIONS_FEED,
    userAction
})

export const contributionsFeedLoaded = (payload) => ({
    type: CONTRIBUTIONS_FEED_LOADED,
    payload
})

export const setContributionsFeedError = (error) => ({
    type: SET_CONTRIBUTIONS_FEED_ERROR,
    error
})

export const activateContributionsRefresh = (activate) => ({
    type: ACTIVATE_CONTRIBUTIONS_REFRESH,
    activate
})

export const fetchSocialFeed = (payload) => ({
    type: FETCH_SOCIAL_FEED,
    payload
})

export const socialFeedLoaded = (platform, posts, initialPayload) => ({
    type: SOCIAL_FEED_LOADED,
    platform,
    posts,
    initialPayload
})

export const setSocialFeedError = (error) => ({
    type: SET_SOCIAL_FEED_ERROR,
    error
})

export const showIntersticiel = (appIsBooting, next) => ({
    type: SHOW_INTERSTICIEL,
    appIsBooting: appIsBooting,
    next: next,
});

export const hideIntersticiel = () => ({
    type: HIDE_INTERSTICIEL,
});

export const showIntersticielCloseButton = () => ({
    type: SHOW_INTERSTICIEL_CLOSE_BUTTON,
});

export const hideIntersticielCloseButton = () => ({
    type: HIDE_INTERSTICIEL_CLOSE_BUTTON,
});


export const showFullLoader = () => ({
    type: SHOW_FULL_LOADER,
});

export const hideFullLoader = () => ({
    type: HIDE_FULL_LOADER,
});


export const flightScheduleClicked = id => ({
    type: FLIGHT_CLICKED,
    data: id
});


export const adClicked = (ad, url) => ({
    type: AD_CLICKED,
    ad: ad,
    url: url
});

export const linkClicked = url => ({
    type: LINK_CLICKED,
    url: url,
});


export const showNotification = opts => ({ type: SHOW_NOTIF, ...opts })

export const editNotification = opts => ({ type: EDIT_NOTIF, ...opts })

export const removeNotification = uid => ({ type: REMOVE_NOTIF, uid })


export const sendAppointmentRequest = (dataId, dataType, dataOriginalId) => ({
    type: SEND_APPOINTMENT_REQUEST,
    dataId,
    dataType,
    dataOriginalId,
});
export const appointmentRequestSent = (dataId, dataType) => ({
    type: APPOINTMENT_REQUEST_SENT,
    dataId,
    dataType,
});
export const appointmentRequestSendResult = (success, dataOriginalId, dataType, dataId, status) => ({
    type: APPOINTMENT_REQUEST_SEND_RESULT,
    success,
    dataOriginalId,
    dataType,
    dataId,
    status,
});

export const contactRequestPerformed = ({ id, dataType, ws, error }) => ({
    type: CONTACT_REQUEST_PERFORMED,
    id,
    dataType,
    ws,
    error,
})

export const realTimeConnected = () => ({
    type: REAL_TIME_CONNECTED,
});
export const realTimeDisconnected = () => ({
    type: REAL_TIME_DISCONNECTED,
});

export const synopticAgendaTabIndexUpdate = index => ({
    type: SYNOPTIC_AGENDA_TAB_INDEX_UPDATE,
    index,
})

export const navigateToSynopticWithoutContext = () => (
    navigate(SYNOPTIC_AGENDA_PAGE_KEY, config.SYNOPTIC_AGENDA.DEFAULT_INPUT)
)

export const adSwap = (adKey, ad) => ({
    type: AD_SWAP,
    adKey,
    ad,
})

export const updateKeyboardState = ({ isOpen, height }) => ({
    type  : KEYBOARD_TOGGLED,
    isOpen: isOpen,
    height: height
});


export const taigaSearch = (fields, dataType) => dispatch => {
    function next({ error, data }) {
        dispatch(taigaSearchPerformed(error, data, dataType));
    }

    switch (dataType) {
        case DATA_TYPE_PARTICIPANTS:
            dispatch(taigaSearchOngoing(dataType));
            ParticipantsService.search(fields, next);
            break;

        default: console.error('Taiga search not implemented yet for data type '+dataType);
    }
}

export const taigaSearchOngoing = dataType => ({
    type: TAIGA_SEARCH_ONGOING,
    dataType: dataType,
    status: STATUS.PENDING,
})

export const taigaSearchPerformed = (error, data, dataType) => ({
    type: TAIGA_SEARCH_PERFORMED,
    error,
    data,
    dataType,
    status: STATUS.FETCHED,
})

export const taigaSearchClear = () => ({
    type: TAIGA_SEARCH_CLEARED,
})


// Related to web Page Visibility API
export const documentVisible = value => ({
    type : DOCUMENT_VISIBLE,
    value,
})


export const klipsoLeadsSetDisclaimerStatus = value => ({
    type: KLIPSOLEADS_SET_DISCLAIMER_STATUS,
    value,
})

export const klipsoLeadsRegisterSuccess = ({
    licence,
    userName,
    instance,
    exhibitorId,
    exhibitorName,
    eventDateBegin,
    eventDateEnd,
    eventDateEndAsLong,
    eventId,
    eventName,
    checkPointId,
    formFields,
}) => ({
    type: KLIPSOLEADS_REGISTER_SUCCESS,
    context: {
        exhibitor: {
            id: exhibitorId,
            name: exhibitorName || '',
        },
        event: {
            dateBegin: eventDateBegin,
            dateEnd: eventDateEnd,
            dateEndAsLong: eventDateEndAsLong,
            id: eventId,
            name: eventName || '',
        },
        instance,
        licence,
        userName,
        checkPointId,
    },
    formFields,
})

export const klipsoLeadsNavigate = props => (
    navigate(KLIPSO_LEADS_PAGE_KEY, props)
    // NB: Props
    //  - searchEnabled
    //  - formScreenEnabled
    //  - extendedMenuScreenEnabled
    // are set to false when no boolean value is provided (see reducer)
)

export const klipsoLeadsSetSortedByCompany = value => ({
    type: KLIPSOLEADS_SET_SORTED_BY_COMPANY,
    value,
})

export const klipsoLeadsEnableSearch = () => (
    klipsoLeadsNavigate({ searchEnabled: true })
)

export const klipsoLeadsExitSearch = () => (
    navigateBack()
    // klipsoLeadsNavigate()
)

export function klipsoLeadsEnableFormScreen(badgeData) {
    let existingContact = getContact(badgeData.code),
        newContact;

    if (!existingContact) {
        newContact = {
            ...badgeData,
        };
    }

    return klipsoLeadsNavigate({
        formScreenEnabled: true,
        currentContact: existingContact || newContact,
    });
}

export const klipsoLeadsExitFormScreen = () => (
    navigateBack()
    // klipsoLeadsNavigate()
)

export const klipsoLeadsContactsUpdated = contacts => ({
    type: KLIPSOLEADS_CONTACTS_UPDATED,
    contacts,
})

export const klipsoLeadsSetSyncOngoing = value => ({
    type: KLIPSOLEADS_SET_SYNC_ONGOING,
    value,
})

export const klipsoLeadsEnableExtendedMenuScreen = () => (
    klipsoLeadsNavigate({ extendedMenuScreenEnabled: true })
)
export const klipsoLeadsExitExtendedMenuScreen = () => (
    navigateBack()
    // klipsoLeadsNavigate()
)

export function klipsoLeadsResetLicence() {
    setContext(null);
    setFormFields(null);
    saveContacts([]);

    return {
        type: KLIPSOLEADS_RESET_LICENCE,
    };
}

export const badgeScanWithoutResult = () => ({
    type: BADGE_SCAN_WITHOUT_RESULT,
})

export const scanContact = () => dispatch => {
    if(isSessionValid()){
         scanNewContact();
         dispatch({
            type: CONTACTS_SCAN_STARTED,
        });
    }
    else{
         dispatch({
            type: CONTACTS_SCAN_UNAUTHORISED,
        });
    }
}
export const contactsUpdated = contacts => ({
    type: CONTACTS_UPDATED,
    contacts,
})

export const userDataTabIndexUpdate = tabIndex => ({
    type: USER_DATA_TAB_INDEX_UPDATE,
    tabIndex,
})

/*
export function saveContact (contat) {
    Contacts.saveContact(contact);
    return {
        type: CONTACTS_SAVED,
    };
}
*/


export const codificationsFetched = codifications => ({
    type: CODIFICATIONS_FETCHED,
    codifications,
})
