
import * as config from 'data/config/config';

import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import {
    CONFIG_JSON_LOADED,
    HAS_NAVIGATED,
    KEYBOARD_TOGGLED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    USER_DATA_UPDATED,
    UPDATE_USER_DATA_REQUEST_STATUS,
    USER_DATA_TAB_INDEX_UPDATE,
    CONTACTS_UPDATED,
    SEARCH_PERFORMED,
    CLEAR_SEARCH_RESULTS
} from 'src/store/actionTypes';

import {
    configJsonLoaded,
    getPageCommonDefaultState,
    profileChanged,
    toggleMenu,
    togglePageAfterNavigation,
    updateKeyboardState,
    updateObject,
    updatePageState,
} from 'src/store/reducers/commons';

import { logout } from 'src/core/login/LoginService';

import { getContacts } from 'src/core/contacts/Contacts';

import { getUserData, getContactedParticipants } from 'src/core/user-data/UserDataService';



function getDefaultState() {
    let commonDefaultState = getPageCommonDefaultState(USER_DATA_PAGE_KEY);

    return Object.assign({}, commonDefaultState, {
        userData: getUserData(),
        contacts: getContacts(),
        tos: config.TOU_LINK[commonDefaultState.labels.id],
        tosLinkTarget: config.TOU_LINK_TARGET,
        externalResourceLinkTarget: config.USER_DATA.externalResourceLinkTarget,
        logout: logout,
        formSections: config.USER_DATA.SECTIONS,
        formStatus: {
            saving: false,
            error: null,
        },
        searchResults: null,
    });
}

export const _langChanged = (state, action) => updateObject(state, {
    labels: action.labels,
    tos: config.TOU_LINK[action.labels.id]
});

const _updateUserdata = (state, action) => updateObject(state, {
    userData: action.userData,
    contacts: getContacts(),
    formStatus: {
        saving: false,
        error: null,
    },
})

const _updateContacts = (state, action) => updateObject(state, {
    contacts: action.contacts
})

const _userDataTabIndexUpdate = (state, action) => {
    let userDataProps = state.userDataProps;
    if (userDataProps && userDataProps.input && userDataProps.input.tabIndex){
        userDataProps.input.tabIndex = null
    }
    return updateObject(state, {
        tabIndex: action.tabIndex,
        userDataProps,
        searchResults: null,
    })
}

function _searchPerformed(state, action) {
    if (action.pageKey !== USER_DATA_PAGE_KEY) {
        return state;
    }
    return updateObject(state, {
        searchResults: action.results,
    });
}

function _clearSearchResults(state, action) {
    if (action.pageKey !== USER_DATA_PAGE_KEY) {
        return state;
    }
    return updateObject(state, {
        searchResults: null,
    });
}


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED: return configJsonLoaded(state);
        case HAS_NAVIGATED: return togglePageAfterNavigation(state, USER_DATA_PAGE_KEY);
        case KEYBOARD_TOGGLED: return updateKeyboardState(state, action);
        case LANG_CHANGED: return _langChanged(state, action);
        case PROFILE_CHANGED: return profileChanged(state, action, USER_DATA_PAGE_KEY);
        case TOGGLE_MENU: return toggleMenu(state, action, USER_DATA_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, USER_DATA_PAGE_KEY);
        case USER_DATA_UPDATED: return _updateUserdata(state, action);
        case CONTACTS_UPDATED: return _updateContacts(state, action);
        case USER_DATA_TAB_INDEX_UPDATE: return _userDataTabIndexUpdate(state, action);
        case UPDATE_USER_DATA_REQUEST_STATUS: return updateObject(state, { formStatus: action.status });
        case SEARCH_PERFORMED: return _searchPerformed(state, action);
        case CLEAR_SEARCH_RESULTS: return _clearSearchResults(state, action);

        default: return state;
    }
};
