import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as actions from "src/store/actions";

import showConfirmModal from "src/core/util/showConfirmModal";

import "./NoteModal.scss";

import { onClickOnLi } from "data/config/listConfig";

import { isIOS } from "src/core/util/browser";

export const COMPONENT_KEY = "NoteModal";
export const CONTAINER_DOM_ID = "note-modal-container";

const DEFAULT_DIALOG_PROPS = {
    // TODO: Export that to data/config/dialogsConfig ?
};

/*
var styleEl = document.createElement("style");
document.head.appendChild(styleEl);

function addStylesheetRules(rules) {
    var styleSheet = styleEl.sheet;
    if (styleSheet.cssRules) {
        for (var i = 0; i < styleSheet.cssRules.length; i++) {
            styleSheet.deleteRule(i);
        }
    }
    for (var i = 0; i < rules.length; i++) {
        var j = 1,
            rule = rules[i],
            selector = rule[0],
            propStr = "";
        // If the second argument of a rule is an array of arrays, correct our variables.
        if (Array.isArray(rule[1][0])) {
            rule = rule[1];
            j = 0;
        }
        for (var pl = rule.length; j < pl; j++) {
            var prop = rule[j];
            propStr +=
                prop[0] +
                ": " +
                prop[1] +
                (prop[2] ? " !important" : "") +
                ";\n";
        }
        // Insert CSS Rule
        styleSheet.insertRule(
            selector + "{" + propStr + "}",
            styleSheet.cssRules.length
        );
    }
}
*/
class NoteModal extends Component {

    handleChange = e => {
        const { itemTitle, itemId, dataType, actions } = this.props;
        const currentNote = e.target.value;
        actions.saveNote(itemTitle, itemId, currentNote, dataType);
    };
    onCloseButtonClick = e => {
        const { actions } = this.props;
        actions.hideNoteModal();
    };
    onDeleteNoteButtonClick = e => {
        const { itemTitle, itemId, dataType, actions, labels } = this.props;
        showConfirmModal({
            text: labels.notes.deleteAlert,
            yesCb: function() {
                actions.deleteNote(itemId, dataType);
            },
            noCb: () => {},
        });
    };
    handleClickOnNoteTitle = e => {
        if (this.props.liEl) {
            onClickOnLi(
                this.props.liEl,
                this.props.actions,
                this.props.labels,
                ""
            );
            this.props.actions.hideNoteModal();
        }
    };
    render() {
        const { itemTitle, itemId, labels, actions, isOpen, liEl } = this.props;

        if (!itemId) {
            return null;
        }
        let dialogProps = Object.assign({}, DEFAULT_DIALOG_PROPS, {
            open: isOpen,
            onClose: actions.hideNoteModal // important, to update `open` value in all cases
        });
        let titleExcerpt =
            itemTitle.length > 50
                ? itemTitle.substring(0, 50) + "..."
                : itemTitle;
        return (
            <Dialog
                {...dialogProps}
                className="dialog-min-width"
                style={
                    this.props.isKeyboardOpen
                        ? isIOS()
                            ? { top: "-40%" }
                            : { top: "-50%" }
                        : { top: 0 }
                }
            >
                <DialogTitle disableTypography={true} className="note-modal-title">
                    {labels.notes.title + " "}
                    <span
                        style={{
                            cursor: liEl ? "pointer" : "default",
                            textDecoration: liEl ? "underline" : "default"
                        }}
                        onClick={this.handleClickOnNoteTitle}
                    >
                        {titleExcerpt}
                    </span>
                </DialogTitle>

                <div className="note-modal">
                    <textarea
                        className="note-modal-textarea"
                        value={this.props.note || ''}
                        onChange={this.handleChange}
                    ></textarea>
                    <div className="note-modal-buttons">
                        <div
                            id="note-modal-deleteButton"
                            className="note-modal-button"
                            onClick={this.onDeleteNoteButtonClick}
                        >
                            {labels.notes.delete}
                        </div>
                        <div
                            id="note-modal-closeButton"
                            className="note-modal-button"
                            onClick={this.onCloseButtonClick}
                        >
                            {labels.common.close}
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

NoteModal.propTypes = {
    itemTitle: PropTypes.string,
    itemId: PropTypes.number,
    dataType: PropTypes.string,
    labels: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    note: PropTypes.string
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoteModal);
