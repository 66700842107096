
import config from 'data/config/config';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { WS_ERRORS, getErrorFromHttpStatus } from '../WS_ERRORS';
import STATUSES from '../statuses';
import { transformData } from './helpers';

const LOG_PREF = '[ParticipantsWS Taiga] ';


export function contactParticipant(id, cb) {
    const url = config.BO_TAIGA_WS_URL+'/contactParticipant.htm';

    let opts = {
        method: 'POST',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        withCredentials: true, // use cookie (the browser takes care of it)
        body: JSON.stringify({ contactId: id }),
    };
    fetchHelper(
        url,
        opts,
        true, // parse response as json
        function onSuccess(response) {
            switch(response.status) {
                // {"status":"success","datas":{"message":"Contact added"}}
                case STATUSES.SUCCESS:
                    cb();
                    break;

                case STATUSES.ERROR:
                    cb(getErrorFromHttpStatus(response.http_code));
                    break;

                default: console.error(LOG_PREF+'Unexpected response status: '+response.status);
            }
        },
        function onFailure(error, message) {
            cb(WS_ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}

export function get(fields, cb) {
    const url = config.BO_TAIGA_WS_URL+'/getParticipants.htm';

    const payload = {};

    if (typeof fields === 'object'
            && fields !== null
            && Object.keys(fields).length > 0) {
        payload.params = {
            // Filter participants with criteria
            filters: fields,
            // Limit results count
            ...config.SEARCH_TAIGA.WS_PARAMS,
        };
    }

    let opts = {
        method: 'POST',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        withCredentials: true, // use cookie (the browser takes care of it)
        body: JSON.stringify(payload),
    };
    fetchHelper(
        url,
        opts,
        true, // parse response as json
        function onSuccess(response) {
            switch(response.status) {
                case STATUSES.SUCCESS:

                    // Format data
                    let participants = [];
                    if (Array.isArray(response.datas)) {
                        participants = response.datas.map(transformData.apiToApp.user);
                    }

                    cb(
                        null, // error code
                        participants,
                    );
                    break;

                case STATUSES.ERROR:
                    cb(getErrorFromHttpStatus(response.http_code));
                    break;

                default: console.error(LOG_PREF+'Unexpected response status: '+response.status);
            }
        },
        function onFailure(error, message) {
            cb(WS_ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}

if (config.ENV === 'dev') {
    global.getParticipants = get;
}