
import codificationsConfig from 'data/config/codificationsConfig';

import * as CodificationsWS from 'src/core/webservices/taiga/CodificationsWS';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import showGenericWsErrorNotification from 'src/core/webservices/showGenericWsErrorNotification';
import { getBindedActions } from 'src/store/bindedActions';
import { isSessionValid } from 'src/core/login/LoginService';


// Local copy of codifications (last known values)
let codifications;


export function init() {
    fetch(
        codificationsConfig.CODIFICATIONS_TO_AUTOMATICALLY_RETRIEVE,
        global.noop
    );
}

export function fetch(codifications, cb) {
    // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
    // can still execute requests on the backend, so it is needed to prevent WS calls
    if (isSessionValid() !== true) {
        cb({ error: WS_ERRORS.AUTH });
    }

    CodificationsWS.getCodifications(
        codifications,
        function _getCodificationsResult(error, data) {
            if (error) {
                showGenericWsErrorNotification(error);
            }
            else if (Array.isArray(data)) {
                data.forEach(function(codif) {
                    setCodification(codif.sourceCode, codif.entityCode, codif.nameField, codif.codif);
                });
                getBindedActions().codificationsFetched(codifications);
            }
            cb(error, data);
        }
    );
}

export function refresh() {
    init();
}

/**
 * Store a codification
 * @param {string} source
 * @param {string} entity
 * @param {string} name
 * @param {object} codif
 */
function setCodification(source, entity, name, codif) {
    if (!codifications) {
        codifications = {};
    }
    if (!codifications[source]) {
        codifications[source] = {};
    }
    if (!codifications[source][entity]) {
        codifications[source][entity] = {};
    }
    codifications[source][entity][name] = codif;
}

/**
 * Get the known value for a specific codification
 *
 * @param  {object} such as:
 * {
 *    sourceCode: string,
 *    entityCode: string,
 *    nameField: string,
 * }
 *
 * @return {object}
 */
export function getCodification({ sourceCode, entityCode, nameField }) {
    if (codifications
            && codifications[sourceCode]
            && codifications[sourceCode][entityCode]) {

        return codifications[sourceCode][entityCode][nameField];
    }
}
