
import React     from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

import FlagButton from './FlagButton';

import './ChooseLangInline.scss';


function ChooseLangInline({ labels, actions, onFlagClick }) {

    return (
        <div id="choose-lang-inline-container">{
            config.SUPPORTED_LANGS.map(lang => (
                <FlagButton
                    key={lang}
                    lang={lang}
                    className={lang === labels.id ? 'is-current-lang' : null}
                    labels={labels}
                    onClick={onFlagClick || actions.setLanguage}
                />
            ))
        }</div>
    );
}

ChooseLangInline.propTypes = {
    labels : PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    onFlagClick: PropTypes.func,
};

export default ChooseLangInline;
