
import { LOGIN_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY } from 'src/pages/pagesKeys';
import { TAIGA_SEARCH_PERFORMED } from 'src/store/actionTypes';

import { navigate, showNotification } from 'src/store/actions';
import { setLoginStatus } from 'src/core/login/LoginService';
import { get as getLabels } from 'src/core/Lang';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';


export default ({ dispatch, getState }) => next => action => {
    const result = next(action);

    switch (action.type) {

        case TAIGA_SEARCH_PERFORMED:
            if (action.error && action.error === WS_ERRORS.AUTH) {
                dispatch(showNotification({
                    message: getLabels().common.invalidAuth,
                    level: NotificationLevels.WARNING,
                }));
                setTimeout(() => {
                    setLoginStatus(false);
                    dispatch(navigate(LOGIN_PAGE_KEY, { nextRoute: { pageKey: SEARCH_TAIGA_PAGE_KEY }}));
                }, 2000);
            }
            break;

        default: // for linter
    }

    return result;
}