var defaultConf = require('./config-default');

var BO_URL = 'aec2020-data-dev.mobile-spot.com';
var BO_TAIGA_URL = 'https://www.assises-economie-circulaire.ademe.fr';
var KLIPSO_LEADS_WS_ROOT = 'http://www.e-registration.net/WebServices/WS_Gaia_Mobile/GaiaMobile';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/www/ademe/aec/aec2020/aec2020-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '/home/www/ademe/aec/aec2020/aec2020-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-29',
  projectId: '42808660360',
  // FCM sender id
  appId: 'BD1E9-EBA5D',
  // pushwoosh appid
  REAL_TIME: {},
  LOGIN: {
    forgottenPasswordURL: BO_TAIGA_URL + '/mobile/mot-de-passe-oublie.htm'
  },
  //BO_TAIGA_WS_URL: BO_TAIGA_URL+'/tgServices/AdemeAppWs',
  //TEMPORARY REVERSE PROXY SETTING FO DEMO PURPOSE
  BO_TAIGA_WS_URL: '/api-calypso',

  /*KLIPSO_LEADS: {
      CHECK_LICENCE_WS_URL: KLIPSO_LEADS_WS_ROOT+'/AuthenticateExhibitorAppByCAB',
      REGISTER_CHECKPOINT_WS_URL: KLIPSO_LEADS_WS_ROOT+'/RegisterCheckPoint',
      GET_FORM_FIELDS_WS_URL: KLIPSO_LEADS_WS_ROOT+'/GetFormFields',
      SAVE_WS_URL: KLIPSO_LEADS_WS_ROOT+'/SaveVisitorRelationList',
  },*/
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([KLIPSO_LEADS_WS_ROOT, BO_TAIGA_URL]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt")
});