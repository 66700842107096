
import React from 'react';
import PropTypes from 'prop-types';

import './Loader.scss';


const Loader = ({ className, theme, labels }) => (

    <div className={'simple-loader '+(theme || ' ')+(className || ' ')}>
        <div className="simple-loader-icon fad fa-sync rotate-center" />
        <div>{ labels.common.loading }</div>
    </div>

);

Loader.propTypes = {
    labels: PropTypes.object.isRequired,
    theme : PropTypes.string,
    className : PropTypes.string,
};

export default Loader;