
import { AUTO_DISMISS } from 'data/config/notifConfig';

import {
    SHOW_NOTIF,
    EDIT_NOTIF,
    REMOVE_NOTIF,
} from 'src/store/actionTypes';

import { updateObject } from 'src/store/reducers/commons';
import { getRandomLetter } from 'src/core/util/JsTools';

import NotificationLevels  from './NotificationLevels';


const getDefaultState = () => ({
    notifications: [],
});


let iNotifId = 1;
/**
 *
 * SEE https://github.com/igorprado/react-notification-system#creating-a-notification
 *
 * @param  {object} action
 * @return {object}
 */
function createNotificationObject(action, edited) {
    let level = action.level || NotificationLevels.INFO,
        duration = typeof action.duration === 'number' ? action.duration : AUTO_DISMISS[level];

    return {
        uid        : action.uid || getRandomLetter()+iNotifId++,
        appId      : action.appId, // provided to be able to find the notification back and edit it
        title      : action.title,
        message    : action.message || ' ',
        level      : level,
        dismissible: duration === 0,
        autoDismiss: duration,
        _onRemove  : action.onRemove,
        // position values: tr (top right), tl (top left), tc (top center), br (bottom right), bl (bottom left), bc (bottom center)
        position   : action.position || 'tc',
        edited     : edited,
    };
}


function findNotifWithSameMessageAndSameLevel(notifications, notifToDisplay) {
    return (notifications || []).find(function(notifDisplayed) {
        return (
            notifDisplayed.level === notifToDisplay.level
                && notifDisplayed.message === notifToDisplay.message
        );
    });
}


/**
 * @see https://github.com/igorprado/react-notification-system#creating-a-notification
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
function _showNotif(state, action) {
    if (!action.message) {
        console.warn('Skipping SHOW_NOTIF action because of empty \'message\' property');
        return state;
    }

    let notificationToDisplay = createNotificationObject(action);

    // Check if this notitication is currently displayed
    if (findNotifWithSameMessageAndSameLevel(state.notifications, notificationToDisplay)) {
        console.log('Skipping notification (already currently displayed)');
        return state;
    }

    return updateObject(state, {
        notifications: state.notifications.concat([ notificationToDisplay ]),
    });
}


function _editNotif(state, action) {
    let notif = state.notifications.find(n => n.uid === action.uid);
    if (!notif) {
        console.error('Failed to find the notification to edit');
        return state;
    }

    let newNotif = createNotificationObject(action, true);
    delete newNotif.uid;

    return updateObject(state, {
        notificationToEdit: {
            existing: notif.uid,
            edited  : newNotif,
        }
    });
}

/**
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
function _removeNotif(state, action) {
    return updateObject(state, {
        notifications: state.notifications.filter(notif => notif.uid !== action.uid),
    });
}


/*
e.g

> show a notif
actions.showNotification({ uid: 12, message: 'truc', duration: 99 });

> edit the displayed notif
actions.editNotification({ uid: 12, title: 'hey dumbass', message: 'truc edited !' });

> remove the notif
actions.removeNotification(12);

 */


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case SHOW_NOTIF: return _showNotif(state, action);
        case EDIT_NOTIF: return _editNotif(state, action);
        case REMOVE_NOTIF: return _removeNotif(state, action);

        default: return state;
    }
};
