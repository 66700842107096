// conf
import config from "data/config/config";

// app modules
import * as Db from "./Db";
import FileSystemHelper from "./FileSystemHelper";

import { startUpdate, getUrl as updaterGetUrl } from "./Updater";

import { addHttpIfNeeded, convertFileSrc } from "src/core/util/JsTools";

import { getBindedActions } from "src/store/bindedActions";

// import TransitionQueuedActions from 'src/core/navigation/transition/TransitionQueuedActions';

const LOG_PREF = "[DataAssetsUtil] ";

export function getUrl(url, useHttp, absolute) {
  //Keep distant absolute url
  if (url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1) {
    // Remove starting '/' if any
    value = url[0] === "/" ? url.substr(1) : url;
    return value;
  }
  // Force data and assets to be fetched from backend if !CORDOVA and !DEV
  if (global.isCordovaContext !== true && process.env.NODE_ENV === "production") {
    useHttp = true;
  }

  let value;

  if (!url) {
    value = "";
  }
  if (useHttp === true) {
    if (url[0] !== "/") {
      url = "/" + url;
    }
    value = addHttpIfNeeded(config.BO_URL + url, true);
  } else {
    // Remove starting '/' if any
    value = url[0] === "/" ? url.substr(1) : url;

    if (global.isCordovaContext) {
      value = updaterGetUrl(value);
    }
    if (absolute === true && value[0] !== "/" && value.indexOf("://") === -1) {
      let loc = document.location.pathname;
      value = loc.substr(0, loc.lastIndexOf("/") + 1) + value;
    }
  }
  value = convertFileSrc(value);
  return value;
}

if (config.ENV === "dev") {
  global.getUrl = getUrl;
}

function indicateDataAndAssetsReady() {
  getBindedActions().dataAssetsUpdated(["all"]);
}

export const init = () => {
  if (!config.BO_URL) {
    // There is no cake backend for this project, so no db/assets to intialize
    indicateDataAndAssetsReady();
    getBindedActions().updaterInitialized();
    return;
  }

  // defer
  window.setTimeout(() => {
    // TransitionQueuedActions.performActionWhenNoTransition(() => {
    console.log(LOG_PREF + "init");
    Db.initialize(() => {
      if (global.isCordovaContext) {
        FileSystemHelper.initialize(() => {
          // Dispatch DB updated event only once assets are ready
          indicateDataAndAssetsReady();

          startUpdate();
        });
      } else {
        indicateDataAndAssetsReady();
      }
    });
    // });
  }, 1);
};
