
import config              from 'data/config/config';
import fetchHelper         from 'src/core/util/FetchHelper';
import { addHttpIfNeeded } from 'src/core/util/JsTools';


const LOG_PREF = '[isOnline] ';


const PING_TIMEOUT = 8000;
const PING_STATUS_VALIDITY = 5 * 1000; // 5 sec

let lastPing, lastStatus;


const commonResultHandler = (status, next) => {
    console.log(LOG_PREF+'is '+(status ? '' : 'not ')+'online');
    lastStatus = status;
    lastPing   = new Date().getTime();

    next(status);
};

const isOnline = next => {

    if (typeof next !== 'function') {
        console.error(LOG_PREF+'invalid callback: ', next);
        return;
    }
    else if (lastPing && lastPing > new Date().getTime()-PING_STATUS_VALIDITY) {
        next(lastStatus);
    }
    else if (!config.CHECK_NETWORK_URL) {
        // No url declared to check network
        next(true);

    } else {
        fetchHelper(
            addHttpIfNeeded(config.CHECK_NETWORK_URL+'?'+new Date().getTime(), true),
            { timeout: PING_TIMEOUT },
            false,
            () => { commonResultHandler(true, next); },
            () => { commonResultHandler(false, next); },
            false
        );
    }
};
export default isOnline;
