
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
    DATA_TYPE_EVENTS,
    DATA_TYPE_SPEAKERS,
    getSpeakerTitle,
    // getSpeakerSubtitle,
} from 'data/config/dataConfig';

import { SPEAKER_PAGE_KEY } from 'src/pages/pagesKeys';

// import DetailCountry      from 'src/components/detail-country/DetailCountry';
// import DetailEvents       from 'src/components/detail-events/DetailEvents';
import DetailFixedTitle   from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailSocialRaw    from 'src/components/detail-social/DetailSocialRaw';
// import Description        from 'src/components/fiche/Description';
import WebsiteRow         from 'src/components/fiche/WebsiteRow';
import NoteButton         from 'src/components/notes/NoteButton';
import List               from 'src/components/list/List';
// import PhoneRow           from 'src/components/fiche/PhoneRow';
// import EmailRow           from 'src/components/fiche/EmailRow';
// import ShareButton        from 'src/components/share-button/ShareButton';
import ContactButton from 'src/components/contact-button/ContactButton';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import '../common-fiche.scss';
import './SpeakerContent.scss';


class SpeakerContent extends PureComponent {

    setScrollableContentEl = el => {
        this.contentContainerEl = el;
    }
    scrollTo = value => {
        if (this.contentContainerEl) {
            this.contentContainerEl.scrollTop = value;
        }
    }

    onClickOnFavIcon = () => {
        this.props.actions.toggleFavorite(
            this.props.item.id,
            DATA_TYPE_SPEAKERS,
            this.props.isFavorite
        );
    }

    showEvents = () => {
        this.props.actions.navigateToItems(
            this.props.item.references[DATA_TYPE_EVENTS],
            DATA_TYPE_EVENTS,
            this.props.item.id,
            DATA_TYPE_SPEAKERS);
    }

    render() {
        const title = getSpeakerTitle(this.props.item);

        return (
            <div className="fiche speaker content-font content-below-apptoolbar">

                {/* title */}
                <DetailFixedTitle
                    title={title}
                    isFav={this.props.isFavorite}
                    onFavClick={this.onClickOnFavIcon}
                    labels={this.props.labels} >

                    <NoteButton
                          itemTitle={title}
                          itemId={this.props.item.id}
                          dataType={DATA_TYPE_SPEAKERS}
                          hasNote={this.props.hasNote}
                          labels={this.props.labels}
                          actions={this.props.actions} />

                    {/* <ShareButton
                        name={this.props.pageTitle}
                        queryString={this.props.queryString}
                        description={description}
                        image={logoUrl}
                        labels={this.props.labels}
                        actions={this.props.actions}
                        profile={this.props.profile}
                        pageKey={SPEAKER_PAGE_KEY} /> */}

                </DetailFixedTitle>

                <div className="content" ref={this.setScrollableContentEl}>
                    <div className="all-informations content-font">

                        <div className="fiche-row1">
                            <div className="fiche-row1-col1">
                                <img className="fiche-photo" src={ getUrl('/'+this.props.item.photo) } alt="speaker photo" />
                            </div>
                            <div className="fiche-row1-col2">
                                <div className="fiche-subtitle"
                                     dangerouslySetInnerHTML={{ __html: this.props.item.lump.subtitle }} />

                                <DetailSocialRaw
                                    data={this.props.item.lump.social}
                                    actions={this.props.actions}/>

                                { this.props.item.logo &&
                                    <div className="fiche-logo">
                                        <img src={ getUrl('/'+this.props.item.logo) } alt="speaker logo" />
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="fiche-row2">
                            { this.props.item.biography &&
                                <div className="fiche-paragraph"
                                     dangerouslySetInnerHTML={{ __html: this.props.item.biography }}
                                     />
                            }
                            <WebsiteRow
                                website={this.props.item.website}
                                actions={this.props.actions} />

                            {/*
                                <PhoneRow phone={this.props.item.phone} />
                                <EmailRow email={this.props.item.email} />
                            */}

                            { this.props.item.address &&
                                <div className="free-row">
                                    <div className="prop-img">
                                        <div className="prop-left">
                                            <div className="fiche-contact-icon">
                                                <span className="fa fa-university" />
                                            </div>
                                        </div>
                                        <div className="prop-right" dangerouslySetInnerHTML={{ __html: this.props.item.address }} />
                                    </div>
                                </div>
                            }
                        </div>

                        <ContactButton
                            participant={this.props.item.references.participant}
                            labels={this.props.labels}
                        />

                        {/* events */}
                        {/* <DetailEvents
                                items={item.events}
                                labels={this.props.labels}
                                onClick={this.showEvents}
                        /> */}
                        { Array.isArray(this.props.item.references.events) && this.props.item.references.events.length > 0 &&
                            <div>
                                <div className="fiche-separator">{ this.props.labels.speaker.relatedInterventions }</div>
                                <List
                                    items={ this.props.item.references.events }
                                    dataType={ DATA_TYPE_EVENTS }
                                    actions={ this.props.actions }
                                    labels={ this.props.labels }
                                    isFastAndUgly={ false }
                                    displayFavorites={ false }
                                    pageKey={ SPEAKER_PAGE_KEY } />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

SpeakerContent.propTypes = {
    item        : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isFavorite  : PropTypes.bool,
    isLogoFullWidth: PropTypes.bool,
    // Common
    queryString : PropTypes.string.isRequired,
    pageTitle   : PropTypes.string.isRequired,
    labels      : PropTypes.object.isRequired,
    actions     : PropTypes.object.isRequired,
};

export default SpeakerContent;
