import React from "react";
import PropTypes from "prop-types";
import loadImage from "blueimp-load-image/js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import * as config from "data/config/config";

import { isCordovaContext, isAndroid } from "src/core/util/browser";
import { getPictureFromCamera, getPictureFromGallery } from "src/core/cordova/cordovaGetPicture";
import NotificationLevels from "src/components-standalone/notifications/NotificationLevels";
import showConfirmModal from "src/core/util/showConfirmModal";
import ImageCrop from "./ImageCrop";

import { convertFileSrc } from "src/core/util/JsTools";

import "./ImageUpload.scss";

const LOG_PREF = "[ImageUpload] ";

export default class ImageUpload extends React.Component {
  static propTypes = {
    image: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
    labels: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    requestStatus: PropTypes.object.isRequired,
  };

  iuConfig = config.IMAGE_UPLOAD;

  constructor(props) {
    super(props);
    this.state = {
      previewUrl: this.props.image ? this.props.image.value : null,
    };

    this.checkConfiguration();

    // Used when taking a picture with cordova-plugin-camera
    this.cordovaGetPictureOptions = {
      targetWidth: this.iuConfig.LOAD_IMAGE_OPTIONS.maxWidth,
      targetHeight: this.iuConfig.LOAD_IMAGE_OPTIONS.maxHeight,
      quality: 100,
    };

    // Style applied to <img>
    this.outputImageStyle = {
      maxWidth: this.iuConfig.DISPLAY_IMAGE_OPTIONS.maxWidth,
      maxHeight: this.iuConfig.DISPLAY_IMAGE_OPTIONS.maxHeight,
    };

    this.load = this.load.bind(this);
    this.onError = this.onError.bind(this);
    this.onPickCordovaMode = this.onPickCordovaMode.bind(this);
  }

  checkConfiguration() {
    if (!this.iuConfig) {
      throw new Error("IMAGE_UPLOAD configuration is missing!");
    }

    let missingConfigParams = [];
    if (!this.iuConfig.LOAD_IMAGE_OPTIONS) {
      missingConfigParams.push("LOAD_IMAGE_OPTIONS");
    }
    if (!this.iuConfig.INPUT_ACCEPTED_MIME) {
      missingConfigParams.push("INPUT_ACCEPTED_MIME");
    }
    if (!this.iuConfig.OUTPUT_MIME) {
      missingConfigParams.push("OUTPUT_MIME");
    }
    if (typeof this.iuConfig.OUTPUT_COMPRESSION !== "number") {
      // value can be 1
      missingConfigParams.push("OUTPUT_COMPRESSION");
    }
    if (missingConfigParams.length > 0) {
      throw new Error(
        missingConfigParams.join(", ") + (missingConfigParams.length > 1 ? " are " : " is ") + "missing from IMAGE_UPLOAD configuration!"
      );
    }
  }

  resetState = () => {
    this.setState({
      previewUrl: null,
      previewloadUrl: null,
    });
    this.inputEl.value = null;
    this.imagePickedByUser = false;
    this.props.onSelect(null);
  };

  setInputRef = (el) => {
    this.inputEl = el;
  };

  pick = (e) => {
    if (this.props.readOnly) {
      return;
    }

    if (this.state.previewUrl) {
      showConfirmModal({
        title: this.props.labels.userData.picture.title,
        text: this.props.labels.userData.picture.confirmSubstitution,
        yesCb: this.proceedToPick,
        noCb: global.noop,
      });
    } else {
      this.proceedToPick();
    }
  };

  proceedToPick = () => {
    // Cordova behavior - See PBC-11 & AR-53
    if (isCordovaContext()) {
      if (!navigator.camera) {
        console.error("Missing cordova-plugin-camera");
        // fallback is default behavior
      } else {
        this.setState({ cordovaSourceTypeModalDisplayed: true });
        return;
      }
    }
    // Default behavior
    this.inputEl.click();
  };

  pickUsingCamera = () => {
    const { specialPermission } = this.props;
    if (specialPermission) {
      showConfirmModal({
        text: specialPermission.text,
        yesBtnLabel: specialPermission.yesBtnLabel,
        noBtnLabel: specialPermission.noBtnLabel,
        yesCb: () => {
          this.hideCordovaSourceTypeModal();
          getPictureFromCamera(this.cordovaGetPictureOptions, this.onPickCordovaMode, this.onError);
        },
        noCb: global.noop,
      });
    } else {
      this.hideCordovaSourceTypeModal();
      getPictureFromCamera(this.cordovaGetPictureOptions, this.onPickCordovaMode, this.onError);
    }
  };

  pickUsingGallery = () => {
    this.hideCordovaSourceTypeModal();
    getPictureFromGallery(this.cordovaGetPictureOptions, this.onPickCordovaMode, this.onError);
  };

  onPickCordovaMode(image) {
    this.imagePickedByUser = true;
    this.load(image);
  }

  onError = () => {
    this.props.actions.showNotification({
      message: this.props.labels.userData.picture.pickError,
      level: NotificationLevels.WARNING,
    });
  };

  handleImageChange = (e) => {
    e.preventDefault();
    console.log(LOG_PREF + "Images picked");

    this.imagePickedByUser = true;

    let image = e.target.files[0];
    if (!image) {
      this.resetState();
    } else {
      this.load(image);
    }
  };

  load(image, size, type, filename) {
    if (!image) {
      this.onError();
      return;
    }
    let quality = this.imagePickedByUser ? 1 : this.iuConfig.OUTPUT_COMPRESSION;
    let options = this.imagePickedByUser
      ? {
          maxWidth: 2000,
          maxHeight: 2000,
          contain: false,
          orientation: false,
          canvas: true,
        }
      : this.iuConfig.LOAD_IMAGE_OPTIONS;
    loadImage(
      image,
      function(result, data) {
        if (result instanceof Event) {
          console.error("Failed to load image " + image.name, result);
          this.onError();
          return;
        }
        console.log(result)
        console.log(LOG_PREF + "Image loaded");

        // NB: result is a canvas element
        let url = result.toDataURL(this.iuConfig.OUTPUT_MIME, quality);

        this.setState({
          previewUrl: url,
        });

        if (!this.imagePickedByUser) {
          this.props.onSelect({
            metaData: {
              name: image.name || filename,
              size: image.size || size,
              type: image.type || type,
            },
            value: url,
          });
        }
      }.bind(this),
      options
    );
  }

  onClearClicked = (e) => {
    e.stopPropagation();
    if (this.props.readOnly) {
      return;
    }
    showConfirmModal({
      title: this.props.labels.userData.picture.title,
      text: this.props.labels.userData.picture.confirmRemoval,
      yesCb: this.resetState,
      noCb: global.noop,
    });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if ((newProps.requestStatus && newProps.requestStatus.saving) || newProps.localPath) {
      // ignore: because image prop has not saved to remote yet or image has a props driven local path
      return;
    }
    if ((!newProps.image || !newProps.image.value) && (this.inputEl.value || this.state.previewUrl)) {
      this.resetState();
    } else if (newProps.image && newProps.image.value !== this.state.previewUrl) {
      this.imagePickedByUser = false;
      this.setState({ previewUrl: newProps.image.value });
    }
  }

  hideCordovaSourceTypeModal = () => {
    this.setState({ cordovaSourceTypeModalDisplayed: false });
  };

  renderCordovaSourceTypeModal = () => (
    <Dialog open={!!this.state.cordovaSourceTypeModalDisplayed} onClose={this.hideCordovaSourceTypeModal}>
      {this.props.sourceTypeModaltitle && <DialogTitle className="title-font modal-title">{this.props.sourceTypeModaltitle}</DialogTitle>}
      <div className="iu-cordova-source-buttons content-font">
        <div className="iu-cordova-source-camera-btn" onClick={this.pickUsingCamera}>
          <i className="fas fa-camera-alt"></i>
          <span>{this.props.labels.common.camera}</span>
        </div>
        <div className="iu-cordova-source-gallery-btn" onClick={this.pickUsingGallery}>
          <i className="fad fa-images"></i>
          <span>{this.props.labels.common.gallery}</span>
        </div>
      </div>
    </Dialog>
  );

  renderImage() {
    if (this.props.localPath) {
      return (
        <div className="iu-preview">
          <img ref={this.setImgRef} style={this.outputImageStyle} src={convertFileSrc(this.props.localPath)} />
          <div className="iu-clear-btn fa fa-times" onClick={this.onClearClicked} />
        </div>
      );
    } else if (this.state.previewUrl) {
      return (
        <div className="iu-preview">
          <img ref={this.setImgRef} style={this.outputImageStyle} src={this.state.previewUrl} />
          <div className="iu-clear-btn fa fa-times" onClick={this.onClearClicked} />
        </div>
      );
    } else {
      return this.props.defaultImageUrl ? (
        <img className="iu-preview-text" src={this.props.defaultImageUrl} alt="" />
      ) : (
        <div className="iu-preview-text">{this.props.labels.userData.picture.selectYours}</div>
      );
    }
  }

  render() {
    return (
      <div className="img-upload">
        {this.renderCordovaSourceTypeModal()}

        <input
          ref={this.setInputRef}
          id="iu-input"
          type="file"
          accept={this.iuConfig.INPUT_ACCEPTED_MIME}
          // capture
          onChange={this.handleImageChange}
        />

        {this.state.previewUrl && this.imagePickedByUser ? (
          <ImageCrop
            src={this.state.previewUrl}
            onCancel={this.resetState}
            onResult={(file, size, type, name) => {
              this.imagePickedByUser = false;
              this.load(file, size, type, name);
            }}
          />
        ) : (
          <div className="iu-preview-container" onClick={this.pick}>
            {this.renderImage()}
          </div>
        )}
      </div>
    );
  }
}
