
import codificationsConfig from 'data/config/codificationsConfig';

import { SET_LOGIN_STATUS } from 'src/store/actionTypes';

import { init } from './CodificationsService';


let isLoggedIn = false;

export default ({ dispatch, getState }) => next => action => {
    const result = next(action);

    switch (action.type) {

        case SET_LOGIN_STATUS:
            if (isLoggedIn !== true
                    && action.loggedIn
                    && codificationsConfig.FETCH_ON_LOGIN_SUCCESS) {
                // Initial fetch of codifications
                init();
            }
            isLoggedIn = action.loggedIn;
            break;

        default: // for linter
    }

    return result;
}