
import { debounce } from 'lodash-custom';

import config from 'data/config/config';
import { DATA_TYPE_PARTICIPANTS } from 'data/config/dataConfig';

import * as ParticipantsWsTaiga from 'src/core/webservices/taiga/ParticipantsWS';
import { parseDate } from 'src/core/webservices/taiga/helpers';
import { TAIGA } from 'src/core/webservices/WsProviders';
import { isSessionValid } from 'src/core/login/LoginService';
import * as UserDataService from 'src/core/user-data/UserDataService';
import * as Db from 'src/core/data-and-assets/Db';
import { getAll } from 'src/core/query/Query';
import { getBindedActions } from 'src/store/bindedActions';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import showGenericWsErrorNotification from 'src/core/webservices/showGenericWsErrorNotification';


const LOG_PREF = '[ParticipantsService] ';


export function isSameUser(participant) {
    if (participant) {
        let userData = UserDataService.getUserData();
        if (userData) {
            return userData.id === participant.id;
        }
    }
    return false;
}

export function hasParticipantBeenContacted(participant) {
    if (participant) {
        let userData = UserDataService.getUserData();
        if (userData && Array.isArray(userData.contactedParticipants)) {
            let contacted = userData.contactedParticipants.find(contact => contact.id === participant.id);
            return !!contacted;
        }
    }
    return false;
}


let lastFetchDate;

function hasDataExpired() {
    if (!lastFetchDate) {
        return true;
    }
    let delaySinceLastFetch = lastFetchDate && new Date().getTime()-lastFetchDate;
    return delaySinceLastFetch > config.NETWORKING.FULL_PARTICIPANTS_DATA_EXPIRE_DELAY;
}


function storeData(participants) {
    // Add participants data to the Db module
    // which allows generic app behaviour for search, filter, favorites, fiches, etc
    Db.appendOrUpdateSortedAndTransformedData(participants, DATA_TYPE_PARTICIPANTS);
}

export function clearData() {
    // Remove participants data from local Db module
    lastFetchDate = null;
    Db.clearData(DATA_TYPE_PARTICIPANTS);
    getBindedActions().dataAssetsUpdated([ DATA_TYPE_PARTICIPANTS ]);
}


/**
 * Fetch participants from web service
 *
 * debounced because it can be called by multiple sources at boot time, e.g:
 *  - after background login success (see participantsMiddleware)
 *  - user landing on participants list
 *
 * @param  {function} cb
 */
export const getParticipants = debounce(cb => {
    let hasCallback = typeof cb === 'function';

    // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
    // can still execute requests on the backend, so it is needed to prevent WS calls
    if (isSessionValid() !== true) {
        if (hasCallback) {
            cb(null, []);
        }
        return;
    }

    if (!hasDataExpired()) {
        if (hasCallback) {
            let participants = getAll(DATA_TYPE_PARTICIPANTS) || []
            cb(null, participants);
        }
        return;
    }

    function next(error, data) {
        if (data) {
            lastFetchDate = new Date().getTime();
            storeData(data);
            if (!hasCallback) {
                getBindedActions().dataAssetsUpdated([ DATA_TYPE_PARTICIPANTS ]);
            }
        }
        if (error === WS_ERRORS.NETWORK) {
            showGenericWsErrorNotification(error);
        }
        if (hasCallback) {
            cb(error, data);
        }
    }

    switch (config.NETWORKING.PROVIDER) {

        case TAIGA:
            ParticipantsWsTaiga.get(null, next);
            break;

        default:
            console.error(LOG_PREF+'Unexpected web service provider: '+config.NETWORKING.PROVIDER);
    }
}, 400);


/**
 * Trigger participant contact by calling web service
 * @param  {number|string} participantId
 * @param  {Function} cb (callback)
 */
export function contactParticipant(participantId, cb) {

    // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
    // can still execute requests on the backend, so it is needed to prevent WS calls
    if (isSessionValid() !== true) {
        cb({ error: WS_ERRORS.AUTH });
    }

    function next(error) {
        getBindedActions().contactRequestPerformed({
            id: participantId,
            dataType: DATA_TYPE_PARTICIPANTS,
            ws: config.NETWORKING.PROVIDER,
            error: error,
        });

        if (typeof cb === 'function') {
            cb(error);
        }
        UserDataService.refreshUserDataFromAPI();
    }

    switch (config.NETWORKING.PROVIDER) {

        case TAIGA:
            ParticipantsWsTaiga.contactParticipant(participantId, next);
            break;

        default:
            console.error(LOG_PREF+'Unexpected web service provider: '+config.NETWORKING.PROVIDER);
    }
}

/**
 * @param  {object}   fields
 * @param  {Function} cb
 */
export function search(fields, cb) {
    if (typeof cb !== 'function') {
        console.error(LOG_PREF+'search: Missing callback');
        return;
    }

    // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
    // can still execute requests on the backend, so it is needed to prevent WS calls
    if (isSessionValid() !== true) {
        cb({ error: WS_ERRORS.AUTH });
    }

    ParticipantsWsTaiga.get(fields, function(error, data) {
        if (error === WS_ERRORS.NETWORK) {
            showGenericWsErrorNotification(error);
        }
        cb({
            error,
            data: Array.isArray(data) ? Db.sortItems(data, DATA_TYPE_PARTICIPANTS) : data,
        });
    });
}


/**
 * @param  {array} ids
 * @param  {function} cb (callback)
 */
export function getParticipantsByIds(ids, cb) {
    // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
    // can still execute requests on the backend, so it is needed to prevent WS calls
    if (isSessionValid() !== true) {
        cb({ error: WS_ERRORS.AUTH });
    }

    if (Array.isArray(ids) !== true || ids.length === 0) {
        cb({});
        return;
    }
    search({ ids: ids }, cb);
}

export function getParticipantsRelatedToAnExhibitor(exhibitorOriginalId, cb) {
    // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
    // can still execute requests on the backend, so it is needed to prevent WS calls
    if (isSessionValid() !== true) {
        cb({ error: WS_ERRORS.AUTH });
    }

    if (!exhibitorOriginalId) {
        cb({});
        return;
    }
    search({ exhibitorId: exhibitorOriginalId }, cb);
}