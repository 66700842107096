
// conf
import {
    get as getHomeConfig,
    getStyle,
} from 'data/config/homeConfig'

import {
    CONFIG_JSON_LOADED,
    CORDOVA_PAUSE,
    CORDOVA_RESUME,
    DATA_ASSETS_UPDATED,
    DOCUMENT_VISIBLE,
    HAS_NAVIGATED,
    LANG_CHANGED,
    POLL_CONFIG_LOADED,
    PROFILE_CHANGED,
    SET_LOGIN_STATUS,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    WINDOW_RESIZED,
} from 'src/store/actionTypes';

import {
    ADD_DUPLICATE_MESSAGE,
    ADD_MESSAGE,
} from 'src/pages/inbox/inboxReducer';

import {
    getPageCommonDefaultState,
    setAppVisible,
    togglePageAfterNavigation,
    toggleLocationStatus,
    toggleMenu,
    updateObject,
    updatePageState,
    windowResized,
} from 'src/store/reducers/commons';

import { HOME_PAGE_KEY, INBOX_PAGE_KEY } from 'src/pages/pagesKeys';

import { get as getLabels } from 'src/core/Lang';

import { getCurrentPageKey } from 'src/core/navigation/CurrentRoute';
import { getAdConfig }    from 'src/core/config-json/ConfigJsonManager';
import { getPollConfig }  from 'src/core/polls/PollManager';
import { getOrientation } from 'src/core/util/DomTools';
import { isSessionValid } from 'src/core/login/LoginService';


function getDefaultState() {
    const
        commonDefaultState = getPageCommonDefaultState(HOME_PAGE_KEY),
        orientation = getOrientation(),
        isLoggedIn = isSessionValid();

    return Object.assign({}, commonDefaultState, {
        homeConfig : commonDefaultState.profile ? getHomeConfig(commonDefaultState.profile, commonDefaultState.labels.id, null, orientation, isLoggedIn) : null,
        homeStyle  : getStyle(),
        orientation: orientation,
        inboxUnreadCount: 0,
        isLoggedIn,
    });
}


function _profileChanged(state, action) {

    // On app initialization, lang file is not loaded yet
    if (getLabels()) {
        let adConfig = getAdConfig(action.profile),
            pollConfig = getPollConfig(action.profile),
            homeConfig = getHomeConfig(action.profile, state.labels.id, adConfig, state.orientation, state.isLoggedIn);

        return updateObject(state, {
            profile   : action.profile,
            homeConfig: homeConfig,
            adConfig  : adConfig,
            pollConfig: pollConfig
        });
    }
    return state;
}

const _langChanged = (state, action) => {
    let newState = updateObject(state, {
        labels: action.labels
    });
    // home config is lang dependant so refresh it too
    return updateHomeConfig(newState);
}

const _windowResized = (state, action) => (
    updateHomeConfig(windowResized(state, action))
)

const _configJsonLoaded = state => {
    let newState = updateObject(state, {
        adConfig: getAdConfig(state.profile)
    });
    return updateHomeConfig(newState);
}

const _pollConfigLoaded = (state, action) => (
    updateObject(state, {
        pollConfig: getPollConfig(state.profile)
    })
)

function _setLoginStatus(state, action) {
    if (state.isLoggedIn !== action.loggedIn) {
        let newState = updateObject(state, {
            isLoggedIn: action.loggedIn
        });
        return updateHomeConfig(newState);
    }
    return state;
}

function _updateHomeAssets(state, action) {
    return updateObject(state, {
        homeConfig: getHomeConfig(state.profile, state.labels.id, state.adConfig, state.orientation, state.isLoggedIn),
        homeStyle  : getStyle()
    })

}

const updateHomeConfig = state => (
    updateObject(state, {
        homeConfig: getHomeConfig(state.profile, state.labels.id, state.adConfig, state.orientation, state.isLoggedIn)
    })
)


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED: return _configJsonLoaded(state);
        case CORDOVA_PAUSE: return setAppVisible(state, false);
        case CORDOVA_RESUME: return setAppVisible(state, true);
        case DATA_ASSETS_UPDATED : return _updateHomeAssets(state, action);
        case DOCUMENT_VISIBLE: return setAppVisible(state, action.value);
        case LANG_CHANGED: return _langChanged(state, action);
        case POLL_CONFIG_LOADED: return _pollConfigLoaded(state, action);
        case PROFILE_CHANGED: return _profileChanged(state, action);
        case SET_LOGIN_STATUS: return _setLoginStatus(state, action);
        case TOGGLE_LOCATION_STATUS: return toggleLocationStatus(state, action);
        case TOGGLE_MENU: return toggleMenu(state, action, HOME_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, HOME_PAGE_KEY);
        case WINDOW_RESIZED: return _windowResized(state, action);

        // Inox count
        case ADD_DUPLICATE_MESSAGE:
        case ADD_MESSAGE:
            if (getCurrentPageKey() !== INBOX_PAGE_KEY) {
                return updateObject(state, { inboxUnreadCount: state.inboxUnreadCount+1 });
            } else {
                return state;
            }

        case HAS_NAVIGATED:
            state = togglePageAfterNavigation(state, HOME_PAGE_KEY);

            if (action.pageKey === INBOX_PAGE_KEY) {
                return updateObject(state, { inboxUnreadCount: 0 });
            } else {
                return state;
            }

        default: return state;
    }
};
