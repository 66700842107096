
import { getBindedActions } from 'src/store/bindedActions';

function toggleKeyboard(isOpen, height) {
    if (window.MobiGeo) {
        window.MobiGeo.setKeyboardStatus(isOpen, height);
    }
    getBindedActions().updateKeyboardState({
        isOpen: isOpen,
        height: isOpen ? height : 0,
    });
}

export function addKeyBoardListeners() {

    // documentation: https://github.com/ionic-team/cordova-plugin-ionic-keyboard

    window.addEventListener("keyboardDidShow", event => {
        toggleKeyboard(true, event.keyboardHeight);
    });

    window.addEventListener("keyboardWillHide", () => {
        toggleKeyboard(false);
    });
    /*window.addEventListener("keyboardDidHide", () => {
        toggleKeyboard(false);
    });*/
}
